.warning{
    color:#721c24
}

.form-main-cnt {
    background-color: #f3f6fd;
    border: 1px solid #e0e0e0;
    padding: 20px;
    /* display:table; */
}

    .form-main-cnt h1, h2, h3, .open-hours-cnt h1, h2, h3 {
        color: #000;
        font-family: 'Exo 2', sans-serif;
        font-size: 22px !important;
        padding-bottom: 10px;
        text-transform: none;
    }

.date-field {
    position: relative;
    display: block;
    border: 1px solid #f9fafb;
    width: 100%;
}


    .date-field:before {
        font-family: 'Font Awesome\ 5 Free';
        content: "";
        position: absolute;
        top: 8px;
        color: #db342b;
        font-weight: 700;
        right: 10px;
        font-size: 20px;
    }

.time-label {
    background-color: #8e8c8d;
    font-size: 1rem;
    color: #fff;
    font-weight: bold;
    line-height: 1.5;
    display: inline-block;
    padding: 10px 16px;
}
.form-mid-section {
    border-top:1px solid #d5d6d8;
    border-bottom:1px solid #d5d6d8;
        padding: 15px 0 0px;
    width:100%;
    margin-top:20px;
    margin-bottom:20px; 
}
.slots-div-main {
width:130px;
float:left;
margin:10px;
text-align:center;
} 
.slots-div {
    background-color:#fff;
    border:1px solid #d5d6d8;
    padding:10px;
    margin-top:10px;
    text-align:left;
    position:relative;
    display:table;
}
    .slots-div .timer {
     color:#e72913;
     font-weight:bold;
     width:100px;
     line-height:normal;
     font-size:14px;
    }
   .slots-div .checklist {
            position: absolute;
    top:10px;
    right: -8px;
    }

.slot-price {
    font-size:14px;
    padding-top:5px;
    color:#000;
    float:left;
}
.slot-price-field {
background-color: #f3f6fd;
    border: 1px solid #d5d6d8;
    padding: 4px 8px;
    font-size: 14px;
    float: left;
    width: 56px;
    /* margin-left: 8px; */
}

.open-hours-cnt {
    max-width: 650px;
    width: 100%;
    margin: 10px auto;
    background-color: #f3f6fd;
    border: 1px solid #e0e0e0;
    padding: 20px;
}


