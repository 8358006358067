.ant-time-picker-panel-select{
    overflow-y: auto !important;
}

.timeField .form-control::placeholder{
    color:#bfbfbf;
}

.timeField .form-control{
    padding: 4px 10px;
    font-size: 14px;
}

.venue__details {
}

.venue__details ul {
    padding-left:0px;
    padding-top:6px;
}

.venue__details ul li {
    padding:6px;
    border-top:1px solid #f1f1f1;
    font-size: 12px;
    text-align: left;
}

.venue__details i{
    color:#e22122;
    margin-right:2px;
}
.venue__details span{
    margin-right:9px;
}




.club-header {
    width: 100%;
    padding: 20px 20px 0 20px;
    background-image: url(../images/club-header-bg.webp);
    background-repeat: repeat-x;
    background-position: center top;
}

.ch-left {
    padding-bottom: 20px;
    padding-top: 20px;
}



.club-info-cnt {
    background-image: url(../images/club-detail-bg.webp);
    background-repeat: repeat-x;
    background-position: center top;
    padding: 60px 0 20px;
    width: 100%;
    color: #fff;
    text-align: center;
    margin-top:15px;
}

    .club-info-cnt h3 {
        color: #fff !important;
        font-size: 24px !important;
    }

.club-main-cnt {
    -webkit-box-shadow: 2px 3px 21px -10px rgba(204,204,204,1);
    -moz-box-shadow: 2px 3px 21px -10px rgba(204,204,204,1);
    box-shadow: 2px 3px 21px -10px rgba(204,204,204,1);
    background-color: #fff;
    width: 100%;
    margin-top: 40px;
}

.club-box {
    border: 1px solid #f1f1f1;
    text-align: center !important;
        padding: 36px 25px 20px;
    color: #000;
    width: 100%;
    height: 100%;
    display: table;
    background-color:#fff;
}

    .club-box:hover {
        background-color: #ea3329;
        color: #fff;
    }

    .club-box p {
        font-size: 14px;
    }

.club-box .club-box-heading {
  font-family: 'Exo 2', sans-serif;
  line-height: 1.5em;
  font-weight:normal;
  text-transform:none;
  padding-top:25px;
  font-size: 20px;
  position: relative;
  position: relative;
  margin-bottom:20px;
}
    .club-box .club-box-heading:hover {
    color:#fff;
    }
.club-box .club-box-heading:after {
  content:' ';
  position: absolute;
  top:100%;
  left:50%;
  width: 20px;
  border:2px solid #d0d0d0;
  border-radius:4px;
  box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05);
  transform:translateX(-50%);
}

.club-box i {
    color: #e22122;
    display: block;
    position: relative;
    z-index: 999999;
}

.club-box i:hover {
        color: #fff;
    }

.club-box:hover, .club-box:hover i {
    background-color: #ea3329;
    color: #fff;
}

.club-section03 {
    background-image: url('../images/clubbg.webp');
    background-repeat:no-repeat;
    background-position:bottom left;
    width:100%;
    padding:70px 0;
    position:relative;
}
.laptop {
   display:none;
}
.club-join {
    background-image: url(../images/club-bottom.webp);
    background-position: center top;
    background-repeat: no-repeat;
    text-align: center;
    padding: 70px 0;
    width: 100%;
    font-size:20px;
    font-weight: bold;
    color:#fff;

}
.top-padding {
    padding:35px;
}

@media (max-width: 1800px) and (min-width: 1200px) {
    .ch-left {
        padding-bottom: 60px;
        padding-top: 100px;
    }
    .p0 {
    padding:0px;
}
    .club-box {
         padding: 60px 25px 50px;
    }
    .laptop {
    position:absolute;
    right:0;
    display:block;
    top:0;
}
     .club-join {
        padding: 100px 0 100px;
         font-size: 30px;
    }
   .top-padding {
    padding: 150px 55px;
}
   .top-txt {
           padding-top: 225px;
   }
    .club-info-cnt {
    padding:60px 0;
    margin-top:0px;
    }
       
}

.manage-hours{
    /* height: 600px; */
    padding:20px;
}

.manage-hours div{
    margin-bottom: 8px;
}

.manage-hours .time{
    display: flex;
    justify-content: space-around;
}

.manage-hours .date{
    display: flex;
    justify-content: space-around;
   
}

.manage-hours .btn-submit{
    text-align: center;
    margin-top:25px;
}

.cb-timeslot{
    margin:20px
}

.tbl-editbulk th{
   padding-right:20px;
}


.modal-dialog{
    max-width: 650px;
  }

  @Media only screen and (min-width: 992px){
   .react-confirm-alert{
       width: 40%;
   }
  }