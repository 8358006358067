
.btn-wt {
    background-color:#fff;
    padding: 6px 24px;
    font-size: 14px;
    border-radius: 30px;
    color:#000;
    border: 1px solid #cecbcb;
}
.btn-settings, .btn-edit-profile, .btn-yellow {
    background: #ea3028; /* Old browsers */
    background: -moz-linear-gradient(left, #ea3028 0%, #f9b043 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ea3028 0%,#f9b043 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #ea3028 0%,#f9b043 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ea3028', endColorstr='#f9b043',GradientType=1 ); /* IE6-9 */
    border: 0;
    color: #fff;
    font-weight: normal;
    padding: 6px 24px;
    font-size: 14px;
    border-radius: 30px;
}
.btn-edit-profile {
margin:4px;
}

    .btn-settings:after, .btn-edit-profile:after {
        font-family: Font Awesome\ 5 Free;
        content: "";
        padding-top: 2px;
        opacity: .8;
        transition: all .3s ease 0s;
        font-weight: 900;
    }

    .btn-edit-profile:after {
        content: "";
        float:left;
        padding-right:10px;
    }

    .btn-settings:focus, .btn-settings.focus, .btn-edit-profile:focus .btn-edit-profile.focus {
    }

    .btn-settings:hover, .btn-edit-profile:hover, .btn-yellow:hover {
        color: #fff;
        background-color: #e92e29;
        border-color: #f9b043;
    }

/*players profile*/
.margin-xs-0 {
    margin:2px;
}
.icons-margin {
        margin: 10px 2px !important;
}




.status-inactive {
    background-color: #f9ac42;
    padding: 10px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: normal;
}
.players-detail-cnt .step .step-item a {
        font-size: 11px;
}

.players-detail-cnt {
    padding:28px;
    width: 100%;
    color: #999999;
}

    .players-detail-cnt h2, .players-detail-cnt h3 {
        text-transform: none;
        color: #000;
        font-weight: 600;
        font-size: 18px !important;
        font-family: 'Exo 2', sans-serif;
        letter-spacing: normal;
        margin-bottom: 15px;
    }

        .players-detail-cnt h2:after {
            content: "";
            display: block;
            border-bottom: 4px solid #e92e29;
            width: 10%;
            position: relative;
            bottom: -5px; /* your padding + border-width */
        }
        .players-detail-cnt h3 span {
            color:#999;
            font-weight:normal;
            line-height:normal;
            padding:0px;
        }

.player-item-box {
    background-color: #fff;
    display: table;
    width: 100%;
    position: relative;
    overflow: hidden;
    border: 1px solid #ddd;
    margin-top: 20px;
    border-bottom-width: 2px;
    -webkit-transition: border 1000ms ease-out;
    -moz-transition: border 1000ms ease-out;
    -o-transition: border 1000ms ease-out;
    transition: border 1000ms ease-out;
    color: #999;
}

    .player-item-box .header {
        width: 100%;
        margin-bottom: 12px;
        position: relative;
        background-color: #f5f5f5;
        text-align: center;
        position: relative;
        box-shadow: inset 0 0 80px rgba(0,0,0,.4);
    }

    .player-item-box .body {
        padding: 0 12px 12px;
        width: 100%;
        text-align: left;
        color: #333333;
    }

        .player-item-box .body a {
            font-size: 12px;
            font-weight: bold;
            color: #282828;
            text-transform: uppercase;
            line-height: normal;
            display: block;
        }

            .player-item-box .body a span {
                display: block;
                font-size: 12px;
                color: #999;
                text-transform: none;
                font-weight: 500;
                line-height: normal;
                padding-top: 6px;
            }

@media (max-width: 1800px) and (min-width: 1200px) {
    .players-list {
        padding: 15px;
    }
      .margin-xs-0 {
        margin: 16px 10px 16px 20px;
    }
    .players-detail-cnt .step .step-item a {
        padding:0px;
        padding-top:18px;
          font-size: 14px;
    }
 
    
}

.choose-sports{
  height: 400px;
  overflow: scroll;
  width: 100%;
  background-color: white;
}

.overflow-cnt{
    width: 100%;
    max-height: 300px;
    overflow: auto;
}
.map-loader{
    text-align: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
    background: white;
    z-index: 10000;
    user-select: none;
    pointer-events: none;
}

.map-loader.fixed{
    position: fixed;
}
.booking-loader{
    text-align: center;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
    background: white;
    z-index: 1;
}

.social-loader{
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    background: white;
    z-index: 1;
}

@media only screen and (max-width: 600px) {
    .no-request-sent{
        font-size: 12px;
        text-align: center;
        margin-top:2px;
    }
    .mb-mr-15{
        margin-right:15px
    }
  }

