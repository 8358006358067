body {
    background-color:#fff !important;
}
h1,h2,h3 {
    font-size: 30px !important;
    font-weight: 600;
    padding-bottom: 30px;
    color:#000;
}
h3 {
padding-bottom:20px;
border-bottom: 0;
}
.home-header-cnt {
background-image: url(../images/headerbg01.webp);
background-position: center top;
background-repeat:no-repeat;
width:100%;
text-align: center;
padding:90px 0;
color:#fff !important;
}
.home-header-cnt h1 {
    font-weight:600;
    font-size: 40px;
    color: #fff !important;
}
.home-header-cnt h1 span {
    font-size: 18px;
    display: block;
    padding-top: 5px;
    color: #fff !important;
}

.home-header-search-cnt {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding-top:20px;
}

.home-header-search-cnt .ant-select-selection {
    border: 1px solid #e0e0e0;
    border-radius: 0;
    font-size: 12px;
    padding: 4px;
    height: auto;
}
.home-header-search-cnt .btn {
border-radius: 0px;
}
.home-infobox-main {
    background: rgb(244,243,243);
background: linear-gradient(0deg, rgba(244,243,243,1) 0%, rgba(255,255,255,1) 100%);
width: 100%;
padding: 26px 0;
line-height: 1;
}
.home-infobox {
    color: #000;
    max-width: 1050px;
    margin: 0 auto;
    width: 100%;
    padding-left: 20px;
}
.home-infobox .info-ico {
    padding: 5px 15px 0 0;
    float: left;
}
.home-infobox .info-text {
    font-size:30px;
    font-weight:600;
}
.home-infobox .info-text span {
    font-size: 16px;
    display: block;
    font-weight: 100;
}
.main-list-item {
    border:1px solid #f1f1f1;
}
.home-orange-box {
    background-image: url(../images/orange-bg01.webp);
    width: 100%;
    padding: 50px 0;
    color: #fff;
    text-align: center;
}
.home-orange-box h2 {
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 30px;
    color: #fff;
}
.home-workbox {
    width: 100%;
    position: relative;
    text-align: center;
}
.home-workbox h3 {
    font-size: 26px;
    font-weight: 500;
    padding: 15px 0 10px 0;
    color: #fff;
    border-bottom: 0;
}
.home-workbox-arrowleft {
    position: absolute;
    right: -52px;
    top: 30px;
}
.home-workbox-arrow-right {
        top:80px;
        position: absolute;
        right:-52px;

}

.home-sports-icon-main {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.home-sports-lg-icon {
    background-image: url('../images/sports_main_icons.webp');
    width: 73px;
    height:73px;
    display: inline-block;
}

    .home-sports-lg-icon:hover, .home-sports-lg-icon.active {
        /* background-position-y: 73px; */
    }

    .home-sports-lg-icon-main .basketball {
    background-position-x: 2060px;
}

.home-sports-lg-icon-main .racing {
    background-position-x: 1980px;
}

.home-sports-lg-icon-main .football {
    background-position-x: 1535px;
}

.home-sports-lg-icon-main .baseball {
    background-position-x: 1485px;
}

.home-sports-lg-icon-main .volleyball {
    background-position-x: 1400px;
}

.home-signup-cnt {
    width: 100%;
    background-image: url('../images/home-signupbg.webp');
    padding: 30px 0;
    color: #fff;
    margin-top: 30px;
    font-size:24px;
}

.home-testi-cnt {
    background-image: url('../images/home-map-bg.webp');
    padding: 60px 0;
    background-color:#fafafa;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
}
.home-testibox {
    width:100%;
    background-color:#fff;
    margin: 10px;
    border: 1px solid #f1f1f1;
}
.home-testibox .home-testi-text {
float: left;
padding: 16px 0 16px 16px;
font-size:14px;
}
.home-testibox .home-testi-text .author-name {
    background-image: url('../images/aut-icon.webp');
    background-repeat: no-repeat;
    background-position:top left;
    font-weight: bold;
    padding-left: 36px;
    margin-top: 14px;
}
.home-testibox .home-testi-text .author-name span {
display: block;
font-size: 12px;
font-weight: 100;
}
.home-testibox .testi-right {
float: right;
}

.home-grey-bg {
   background: rgb(250,250,250);
background: linear-gradient(180deg, rgba(250,250,250,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%);
}
.home-footer-bg {
    background-color: #030303;
    background-image: url('../images/home-footerbg.webp');
    background-repeat: no-repeat;
    background-position: top left;
    padding:60px 0;
    color: #fff;
}
.home-footer-bg p {
font-size: 12px;
}
.home-footer-bg .ft-phone{
font-weight: 600;
font-size:22px;
}
.social-network {
    width:100%;
    }
    .social-network h2, .footer-links h2 {
    font-size:16px !important;
    font-weight:500;
    padding-bottom:6px;
    color:#fff;
    }
    
    /* footer social icons */
    ul.social-network {
        list-style: none;
        display: inline;
        margin-left:0 !important;
        padding: 0;
    }
    ul.social-network li {
        display: inline;
        margin-right:5px;
    }
    
    /* footer social icons */
    
    .social-network a.icoRss:hover i, .social-network a.icoFacebook:hover i, .social-network a.icoTwitter:hover i,
    .social-network a.icoGoogle:hover i, .social-network a.icoVimeo:hover i, .social-network a.icoLinkedin:hover i {
        color:#000;
    }
    a.socialIcon:hover, .socialHoverClass {
        color:#44BCDD;
    }
    
    .social-circle li a {
        display:inline-block;
        position:relative;
        margin:0 auto 0 auto;
        -moz-border-radius:50%;
        -webkit-border-radius:50%;
        border-radius:50%;
        text-align:center;
        width: 45px;
        height: 45px;
        font-size:20px;
    }
    .social-circle li i {
        margin:0;
        line-height:45px;
        text-align: center;
    }
    
    .social-circle li a:hover i, .triggeredHover {
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms--transform: rotate(360deg);
        transform: rotate(360deg);
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        -ms-transition: all 0.2s;
        transition: all 0.2s;
        color:#000;
    }
    .social-circle i {
        color: #000;
        -webkit-transition: all 0.8s;
        -moz-transition: all 0.8s;
        -o-transition: all 0.8s;
        -ms-transition: all 0.8s;
        transition: all 0.8s;
    }
    .social-network a {
     background-color: #fff;   
    }
    .social-circle i:hover {
    color: #000;
    }
    
    .footer-links {
    width:100%;
    display:table;
    }
    
    .footer-links ul {
    width:100%;
    margin:0px;
    padding:0px;
    list-style:none;
    }
    .footer-links ul li{
    padding-bottom:4px;
    }
    .footer-links ul li a {
    font-size:12px;
    color:#fff;
    }
    
.footer-bottom {
    width: 100%;
    padding-top: 20px;
    font-size: 12px;
    border-top: 1px solid #292929;
    margin-top: 18px;
}
.owl-nav {
display:none;
}

.sec-lg {
    padding: 60px 0; 
}
.signup-r {
    text-align: right;
}
.signup-l {
    text-align: left;
}



@media (max-width:767px) {
    .signup-r {
        text-align: center;
        padding-bottom: 10px;
    }
    .signup-l {
        text-align: center;
    }
    
.home-header-cnt {
    padding:40px 0;
}
    .home-header-cnt h1 {
        font-size: 28px;
    }
    .home-header-cnt h1 span {
        font-size: 16px;
        font-weight: 100;
    }
    .home-infobox .info-text {
        font-size: 30px;
 
    }
    .home-infobox .info-text span {
        font-size: 13px;
    }
    .info-padding {
        padding-bottom:20px;
    }
    .home-workbox-arrowleft,  .home-workbox-arrow-right {
        display: none;
    }
    .home-workbox {
        padding: 0 20px 20px 20px;
    }
	.home-sports-lg-icon {
    width: 70px;
    height: 70px;
	}
}
