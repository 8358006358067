body {
    background-color:#f8f8f8; 
    font-family: 'Poppins', sans-serif;
  color:#000;
 }
 .venue-top {
     width: 100%;
     padding:30px 0;
 }
 .venue-top h1 {
     font-size:22px;
 }
 .venue-top h1 span {
 font-size: 14px;
 font-weight: normal;
 color:#5b5b5b;
 display: block;
 }
 .venue-top-list-icon, .venue-top-map-icon {
     font-family: 'Font Awesome\ 5 Free';
     display: inline-block;
     font-weight: 900;
     font-size: 18px;
     color: #ec3f2b;
     background-color: #fff;
     padding: 2px 6px;
     border: 1px solid #ddd;
     border-radius: 4px;
     margin-top: 4px;
 }
 .venue-top-list-icon:before, .venue-top-map-icon:before {
         content: "";
 }
 .venue-top-map-icon:before {
      content: "";
 }
 .playerwhitebg {
     padding:0 12px;
     width: 100%;
     background-color: #fff;
     margin-bottom: 15px;
 }
 .playerwhite-inner {
 padding: 12px 0;
 }
 .player-left-panel .card-header {
     padding: 5px 0;
     background-color:#fff;
 }
 .player-left-panel .card {
     border:none;
 }
 .player-left-panel .card-body {
     padding: 10px 0;
 }
 .player-left-panel .btn-link {
     width: 100%;
     text-align: left;
     font-size: 14px;
     color: #000;
     font-weight: 600;
     padding: 0px;
 }
 .player-left-panel .btn-link:hover, .btn-link:focus {
 text-decoration:none;
 }
 .player-left-panel i {
     float: right;
     background-color: #f1f1f1;
     font-size: 11px;
     padding: 4px;
     border-radius: 10px;
     color: #b8b8b8;
 }
 .player-left-panel .form-control {
     border: 1px solid #e0e0e0;
     border-radius: 0px;
     font-size: 12px;
     padding: 10px;
     margin-bottom: 12px;
 }
 .venue-result {
     font-size: 14px;
     padding-top: 10px;
 }
 .sort-drop-down {
     font-size: 12px;
     width: 100%;
     background-color: #f1f1f1;
     border: none;
     padding: 10px 4px;
 }
 
 /* list item  */
 .venue-list-item {
     background-color: #ffffff;
     width: 100%;
     position: relative;
     overflow: hidden;
     margin-bottom: 30px;
 }
 .venue-list-item .header {
     width: 100%;
     position: relative;
     background-color: #f5f5f5;
     position: relative;
     box-shadow: inset 0 0 80px rgb(0 0 0 / 40%);
 }
 .venue-list-item.header > .header-content {
     position: absolute;
     top: 0;
     left: 0;
     bottom: 0;
     right: 0;
   
 }
 
 .venue-list-item .header .header-bottom, .venue-list-item .header .header-top {
     position: absolute;
     bottom:0px;
     z-index: 2;
     padding: 8px 10px;
     width: 100%;
     color: #fff;
     background: linear-gradient(rgba(22,22,22,0),rgba(22,22,22,22));
 }
 .venue-list-item .header .header-top {
     top:0px;
     background: none;
   
 }
 .venue-list-item .player-venue-price {
     float: left;
     font-size: 18px;
     font-weight: 600;
 }
 .venue-list-item .fav-icon {
     float: right;
     font-size: 18px;
 }
 .venue-list-item .body {
     width: 100%;
     padding:12px 10px;
     font-size: 14px;
     font-weight: 500;
     margin-bottom: 20px;
 }
 .venue-list-item .body .address {
     position: relative;
     padding-left: 20px;
     display: inline-block;
     font-size: 12px;
     color: #929191;
     font-weight: normal;
     padding-top: 4px;
 }
 .venue-list-item .body .address:before {
     font-family: 'Font Awesome\ 5 Free';
     content: "";
     left: 0;
     position: absolute;
     top: 4px;
     color: #908f8f;
     font-weight: 600;
 }
 .venue-list-item .footer {
     border-top:1px solid #f1f1f1;
     padding:10px;
 }
 .venue-list-item .footer .ft-item {
 font-size:12px;
 padding-right: 6px;
 display: inline-table;
 }
 
 
 
 
  @media (max-width:767px) {
     .venue-top h1 {
         font-size:18px;
     }
     .venue-top h1 span {
         font-size:12px;
     }
     .venue-result {
     padding-bottom: 10px;
     }
 }
 