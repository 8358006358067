body {
    background-color:#fff !important; 
    font-family: 'Poppins', sans-serif;
  color:#000;
 }
 

 .venue-top {
     width: 100%;
     padding:30px 0;
 }
 .venue-top h1 {
     font-size:22px;
 }
 .venue-top h1 span {
 font-size: 14px;
 font-weight: normal;
 color:#5b5b5b;
 display: block;
 }

 .venue-header-cnt h1 {
    font-size: 14px;
    font-weight: 500;
    color:#f1f1f1;
 }


 
    
    
 
 .list-icon, .map-icon, .heart-icon, .share-icon {
     font-family: 'Font Awesome\ 5 Free';
     display: inline-block;
     font-weight: 900;
     font-size: 18px;
     color: #ec3f2b;
     background-color: #fff;
     padding: 2px 6px;
     border: 1px solid #ddd;
     border-radius: 4px;
     margin-top: 4px;
     margin-right: 4px;
 }
 .list-icon:before, .map-icon:before {
         content: "";
 }
 .heart-icon:before {
     content: "";
 }
 .share-icon:before {
     content: "";
 }
 .map-icon:before {
      content: "";
 }
 .playerwhitebg {
     padding:0 12px;
     width: 100%;
     background-color: #fff;
     margin-bottom: 15px;
 }
 .playerwhite-inner {
 padding: 12px 0;
 }
 .player-left-panel .card-header {
     padding: 5px 0;
     background-color:#fff;
 }
 .player-left-panel .card {
     border:none;
 }
 .player-left-panel .card-body {
     padding: 10px 0;
 }
 .player-left-panel .btn-link {
     width: 100%;
     text-align: left;
     font-size: 14px;
     color: #000;
     font-weight: 600;
     padding: 0px;
 }
 .player-left-panel .btn-link:hover, .btn-link:focus {
 text-decoration:none;
 }
 .player-left-panel i {
     float: right;
     background-color: #f1f1f1;
     font-size: 11px;
     padding: 4px;
     border-radius: 10px;
     color: #b8b8b8;
 }
 .player-left-panel .form-control {
     border: 1px solid #e0e0e0;
     border-radius: 0px;
     font-size: 12px;
     padding: 10px;
     margin-bottom: 12px;
 }
 .player-left-panel .btn:focus, .btn.focus {
     outline: 0;
     box-shadow:none;
 }
 
 .venue-result {
    font-size: 14px;
    padding-top: 8px;
}

 .sort-drop-down {
     font-size: 12px;
     width: 100%;
     background-color: #f1f1f1;
     border: none;
     padding: 10px 4px;
 }
 
 .col-container {
     display: flex;
     width: 100%;
   }
   .flexbox {
     flex: 1;
     background-color:#fff;
   }
 
 
 /* list item  */
 .main-list-item {
    background-color: #ffffff;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    
}
.main-list-item:hover {
    box-shadow: 0 0 22px 0 #ccc;
    cursor: pointer !important;
}
.main-list-item .header {
    width: 100%;
    position: relative;
    background-color: #f5f5f5;
    position: relative;
    box-shadow: inset 0 0 80px rgb(0 0 0 / 40%);
}
.main-list-item.header > .header-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  
}

.main-list-item .header .header-bottom, .main-list-item .header .header-top {
    position: absolute;
    bottom:0px;
    z-index: 2;
    padding: 8px 10px;
    width: 100%;
    color: #fff;
    background: linear-gradient(rgba(22,22,22,0),rgba(22,22,22,22));
}
.main-list-item .header .header-top {
    top:0px;
    background: none;
  
}
.main-list-item .player-venue-price {
    float: left;
    font-size: 18px;
    font-weight: 600;
}
.main-list-item .player-venue-price span {
    font-size: 12px;
    display: block;
    margin: 0px;
    padding: 0;
    line-height: 0;
    font-weight: normal;
    padding-bottom: 4px;
}
.main-list-item .fav-icon {
    float: right;
    font-size: 18px;
}
.main-list-item .body {
    width: 100%;
    padding:12px 10px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}
.main-list-item .body p {
font-size: 12px;
color:#8c8c8c;
font-weight: normal;
}
.main-list-item .btn-sm {
    font-size: .7rem;
}



.main-list-item .body .address, .main-list-item .ft2, .main-list-item .body .date   {
    position: relative;
    padding-left: 20px;
    display: inline-block;
    font-size: 12px;
    color: #929191;
    font-weight: normal;
    padding-top: 4px;
}
.main-list-item .body .address:before, .main-list-item .ft2:before, .main-list-item .body .date:before {
    font-family: 'Font Awesome\ 5 Free';
    content: "";
    left: 0;
    position: absolute;
    top: 4px;
    color: #908f8f;
    font-weight: 600;
}
 

.main-list-item .body .date:before {
    /* content: "?"; */
}
.main-list-item .c-sports {
    color: #929191;
    font-size:12px;
}
.main-list-item .c-sports span {
font-weight: bold;
color:#e82b08;
padding-top:5px;
}
.main-list-item .body .bodylink {
    text-decoration: none;
    border-left: 2px solid #e92e29;
    padding-left: 10px;
    color: #000;
    display: table;
    margin:10px 0; 
    font-size: 15px;
}

.main-list-item .footer {
    border-top:1px solid #f1f1f1;
    padding:10px;
}

.main-list-item .footer ul {
width: 100%;
margin: 0px;
    padding: 0px;
}
.main-list-item .footer ul li {
    border-bottom:1px solid #f1f1f1;
    font-size: 12px;
    padding: 10px;

}
.main-list-item .footer ul li:last-child {
border-bottom: none;
}
.main-list-item .footer .ft-item {
font-size:12px;
padding-right: 6px;
display: inline-table;
}

.main-list-item .body h3 {
    font-size:14px;
    font-weight:500;
    }
    .main-list-item .body h3 span {
    display:block;
    font-size:12px;
    color: #e82b08;
    }
    .main-list-item .tgreen {
    color:#008e3c;
    }
    .main-list-item .tred {
        color:#dc3545;
        }
        .main-list-item .tyellow {
            color:#ffc107;
            }
    .main-list-item .footer .date, .main-list-item .footer .tim, .main-list-item .footer .spt      {
        position: relative;
        padding-left: 20px;
        display: inline-block;
        font-size: 12px;
        color: #929191;
        font-weight: normal;
        padding-top: 4px;
    }
    
    .main-list-item .footer .date:before, .main-list-item .footer .tim:before, .main-list-item .footer .spt:before  {
        font-family: 'Font Awesome\ 5 Free';
        content: "";
        left: 0;
        position: absolute;
        top: 4px;
        color: #908f8f;
        font-weight: 600;
    }
    .main-list-item .footer .tim:before  {
        content: "";
    }
    .main-list-item .footer .spt:before  {
        content: "";
    }
    
    
    .main-list-item .header .header-bottom a {
        color: #fff;
    }
 
 
    




 .font-lg {
     font-size: 12px;
     color:#8c8c8c;
     font-weight: normal;
     }
 
 .venue-header-cnt {
     background-image: url(../images/venue-header.webp);
     background-position: center top;
     background-repeat: no-repeat;
     box-shadow: inset 0 0 90px rgb(0 0 0 / 50%);
     padding-top: 120px;
     padding-bottom:30px;
     position: relative;
     color: #fff;
 }
 .v-h-align {
     text-align: right;
 }
 
 
 .btn:focus, .btn.focus {
     outline: 0;
     box-shadow: 0 0 0 0.2rem rgb(213 213 214 / 25%);
 }
 .form-control {
     border: 1px solid #e0e0e0;
     border-radius: 0px;
     font-size: 12px;
     padding: 10px;
     width:100% !important;
 }
 .aminites {
     width: 100%;
     display: table;
 }
 .aminites ul {
     width: 100%;
     margin:0px;
     padding:0px;
     list-style: none;
 }
 .aminites ul li {
     font-size: 12px;
     color:#262626;
     float: left;
     padding-right: 12px;
 }
 /* .aminites ul li:before {
     font-family: 'Font Awesome\ 5 Free';
     content: "";
     color:#e92e29;
     font-weight: 600;
     PADDING-RIGHT:6PX;
 } */
 .siderbar-float {
     width:100%;
     display:table;
 }
 .siderbar-float-floating {
     z-index: 999;
     position: fixed;
     top: 0;
     width:255px;
 }
 
 .whitebg-div {
     padding:12px;
     width: 100%;
     background-color: #fff;
     margin-bottom: 15px;
 }
 .contact-info {
     border-bottom: 1px solid #f1f1f1;
     padding-bottom: 10px;
     margin-bottom:20px;
 }
 .siderbar-float .address, .email, .phone, .cal {
     position: relative;
     padding-left:26px;
     display: inline-block;
     width: 100%;
     font-size:13px;
     font-weight: normal;
     padding-bottom: 10px;
 }
 .siderbar-float .address:before, .email:before, .phone:before, .cal:before {
     font-family: "Font Awesome 5 Free";
     content: "";
     left: 0px;
     position: absolute;
     top: 2px;
     color:#e92e29;
     font-weight: 900;
     font-size:14px;
 }
 .siderbar-float .email:before {
     content: "";
 }
 .siderbar-float .phone:before {
     content: "";
 }
 .siderbar-float .cal:before {
     content: "";
 }
 .pb-23 {
     padding-bottom:23px;
 }
 .centered {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     text-shadow: 2px 2px #000;
     text-align: center;
     font-weight:normal;
     color: #fff;
     font-size:26px;
     line-height: 22px;
     text-transform: uppercase;
   }
   .centered span {
 display: block;  
 font-size:14px;
 }
 
 
 
 .btn-arrow:after {
     font-family: Font Awesome\ 5 Free;
     content: "";
     margin-left: 10px;
     opacity: .8;
     transition: all .3s ease 0s;
     font-weight: 900;
  }
   
   .btn-arrow:hover:after {
     margin-left: 15px;
   }
 
   /* normal buttons  */
 
   .btn-main {
     color: #fff;
     background-color:#ec3f2b;
     border:1px solid #fc4a1a;
     padding: 0.375rem 0.75rem;
     border-radius:4px;
   }
   
   .btn-main:hover {
     color: #fff;
    background-color:#f9513e;
     border:1px solid #fc4a1a;
   }
 
 .btn-main.disabled, .btn-main:disabled {
     color: #fff;
     background-color: #d23422;
     border-color: #fc4a1a;
  }
 
  .btn-sub {
     color: #fff;
     background-color:#121010;
     border:1px solid #484747;
     padding: 0.375rem 0.75rem;
     border-radius:4px;
   }
   
   .btn-sub:hover {
     color: #fff;
    background-color:#2f2f2f;
     border:1px solid #2f2f2f;
   }
 
 .btn-sub.disabled, .btn-sub:disabled {
     color: #fff;
     background-color:#2f2f2f;
     border:1px solid #2f2f2f;
  }
 
  .btn-main-outline {
     color: #ec3f2b;
     background:none;
     border:1px solid #ec3f2b;
     padding: 0.375rem 0.75rem;
     border-radius:4px;
   }
   
   .btn-main-outline:hover {
    border:1px solid #fc4a1a;
   }
 
   .btn-main-outline .disabled,  .btn-main-outline :disabled {
     border:1px solid #fc4a1a;
  }
  .btn-sub-outline {
     color: #121010;
     background:none;
     border:1px solid #121010;
     padding: 0.375rem 0.75rem;
     border-radius:4px;
   }
   
   .btn-sub-outline:hover {
    border:1px solid #2f2f2f;
    color:#525151;
   }
 
   .btn-sub-outline .disabled,  .btn-sub-outline :disabled {
     border:1px solid #2f2f2f;
  }
 
 
 
  .collapse-search .accordion>.card>.card-header { 
    margin-bottom: 0;
    }
    .collapse-search h2 {
        font-size: 2rem !important;
     margin:0px !important;
    padding:0px !important;;
    color:#000;
        padding-bottom: 2px !important;
    }
    .collapse-search .card-header {
    padding: 0 !important;
        background-color: #fff !important;
        margin: 0px !important;
        border-bottom: 1px solid rgba(0,0,0,.125); 
    }
    .collapse-search .card-header:last {
     border-bottom:none !important;
    }
    .collapse-search .btn-link {
        width: 100%;
        text-align: left;
        font-size: 14px;
        color: #000;
        font-weight: 600;
        padding: 0;
    }
    .collapse-search  .card {
        margin-bottom:0px;
    }
    
    .collapse-search .ant-select-selection {
    border: 1px solid #e0e0e0;
        border-radius: 0px;
        font-size: 12px;
        padding: 4px;
        margin-bottom: 12px;
        height: auto;
    }

    .collapse-search .ant-select-selector, .ant-picker {
            margin-bottom: 12px;
        }

    .collapse-search .ant-calendar-picker, .ant-time-picker-input {
     display:block;
    }
    
    .collapse-search .venue-picker {
    border: 1px solid #e0e0e0;
        border-radius: 0px !important;
        font-size: 12px;
    margin-bottom: 12px;
    height: 38px;
    background-color:#fff !important;
    }

    .collapse-search .ant-calendar-picker-input {
        border: 1px solid #e0e0e0;
        border-radius: 0px !important;
        font-size: 12px;
    margin-bottom: 12px;
    height: 38px;
    background-color:#fff !important;
    }
    .collapse-search .ant-time-picker-input{
          border: 1px solid #e0e0e0;
        border-radius: 0px !important;
        font-size: 12px;
    margin-bottom: 12px;
    height: 38px;
    background-color:#fff !important;
    }

    .collapse-search .ant-time-picker {
        width: 100% !important;
    }
    .collapse-search  .ant-calendar-picker-icon, .ant-time-picker-icon {
        top: 36% !important;
    }

 
 
 
  @media (max-width:767px) {
     .venue-top h1 {
         font-size:18px;
     }
     .venue-top h1 span {
         font-size:12px;
     }
     .venue-result {
     padding-bottom: 10px;
     }
   .v-h-align {
         text-align:left;
         padding-top: 10px;
     }
     .siderbar-float-floating {
        
         position:relative;
         width:100%;
      }
 }
 
.summary-margin{
    margin-top: -159px;
}