.login-left{
    margin-left:100px;
}
/* 
.info-window{
    width:50%
} */

.navmain-cnt {
        box-shadow: 0 8px 6px -6px #e2e2e2;
    background-color: #FFF;
    width: 100%;
    padding:0 0 0 0;
    position:relative;
}

@media (max-width: 991px) {
    .navmain-cnt {
        padding:10px 0 0 0;
    }
}

.navmain-cnt .ant-select-selection {
    height: auto;
}

    .navmain-cnt .navbar {
        padding: 0px;
    }
    .nav-top { 
      
        margin-top:10px;
        padding-left:0px;
        padding-right:0px; 
    }


    .navmain-cnt .navbar-nav li:last-child {
            BORDER-BOTTOM:none;
    }

        .navmain-cnt .navbar-nav li a {
            background-color: #fff;
            padding: 15px 15px 15px 15px !important;
            font-weight: 600;
            color: #000 !important;
            font-size:13px;
        }

            .navmain-cnt .navbar-nav li a:hover {
                color: #fff !important;
                background-color: #e92e29;
            }

            .navmain-cnt .navbar-nav li a.active {
                color:#e92e29  !important;
                background-color: #FFF;
            }

.btn-nav {
    position: relative;
    padding: 8px 10px;
    width: initial;
    margin-top: 0;
    font-weight: 400;
    font-size: 22px;
    border-radius: 20px;
    display:none;
}

.before-login {
    /* margin-top: 8px; */
}

/* .navmain-cnt .dropdown-menu {
        min-width:6rem;
        width: 100% !important;
    } */
.btn-nav, btn-nav:focus, btn-nav:visited {
    color: #fff !important;
    background: #ea3028;
    background: -moz-linear-gradient(left, #ea3028 0%, #f9b043 100%);
    background: -webkit-linear-gradient(left, #ea3028 0%,#f9b043 100%);
    background: linear-gradient(to right, #ea3028 0%,#f9b043 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ea3028', endColorstr='#f9b043',GradientType=1 );
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    border: 0;
    transition: all .3s ease 0s;
}

    .btn-nav:hover {
        box-shadow: inset 0 0 0 2px #f58f0d;
        color: #fff;
    }

    .btn-nav:after {
        font-family: Font Awesome\ 5 Free;
        content: "";
        padding-right: 6px;
        padding-top: 2px;
        float: left;
        opacity: .8;
        transition: all .3s ease 0s;
        font-weight: 900;
    }

    .btn-nav:hover::after {
        opacity: 1;
    }

.bell {
         padding: 8px 10px 8px 0px;
    height: auto !important;
    display: inline-table;
    max-width: 100px;
    margin-right:10px;
}
.count, .nft-count {
    position: absolute;
    top: 12px;
    margin-left: 8px;
    font-size: 9px;
    font-weight: bold;
    padding: 1px 5px;
    text-align: center;
    color: #ffffff;
    border-radius: 30px;
    background-color: #eb3329;
}
.nft-count {
background-color: #f7901e;
}
.user-mail {
    padding: 10px 12px 8px 0px;
    height: auto !important;
    display: inline-table;
    max-width:100px;
    margin-right:10px;

}
.user-profile {
    margin-right: 6px;
    height: auto !important;
    display: inline-table;
}
    .user-profile a {
    font-size:13px;
    font-weight:bold;
    font-weight:normal;
    padding:0px;
    }
    .user-img {
        width:30px;
    }
.user-nav-none {
display:none;
}
a {
    color: #000;
}
.cart-icon {
     padding: 10px 26px 8px 0px;
    border-right: 1px solid #E2DFDF;
    height: auto !important;
    float: left;
    margin-right:10px;
}

    .cart-icon a {
        color: #000;
    }

    .cart-icon .cart-count {
        position: absolute;
        top:8px;
        margin-left: 13px;
        font-size: 10px;
        font-weight: bold;
        padding: 2px 6px;
        text-align: center;
        color: #ffffff;
        border-radius: 30px;
        background-color: #e92e29;
    }

.lang-cnt {
    color: #616161;
    padding-top:8px;
    font-size: 14px;
}

.logo {
    max-width:100%;
    margin-top: -7px;
}
.navmain-cnt .navbar-toggler {
    font-size:18px;
    color:#000;
    padding:6px;
    background-color:transparent;
    border:none;
    border-radius:initial;
}
.navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none;
    border:none;
}

.navmb {
    position: absolute;
    top: 0;
    right:19px;
    width:205px;
}

.navmb  .dropdown {
       position: initial;
}

.navmb .dropdown-menu {
        left: -46px ;
        top: 145%;

}
.navmb .dropdown-item {
padding: 0.25rem 0.5rem;
}

.lang-before-login {

    float: left;
    padding-top: 4px;
    width:30%;
}
.navmb-login {
    width:225px;
    /* padding: 15px 0px; */
}
.user-profile .dropdown-menu {
    min-width: 7rem;
    position: absolute !important;
    left: -6 px !important;
}
.mb-logo {
    width: 50%;
}

.lang-desktop-login{
    width:9%;
}


@media (max-width: 768px) and (min-width: 600px) {
    .nav-top {
    border-top: 1PX SOLID #e2e1e1;
}

}

@media (min-width: 1800px){
    .navmb-login{
        margin-top:12px;
    }
    .before-login{
        margin-top:8px;
    }
}



@media (max-width: 1800px) and (min-width: 1200px) {
    .navmain-cnt .ant-select-selection {
        margin-left: 10px;
    }
    .lang-desktop-login {
        position: absolute;
right: -60px;
    }
    .before-login {
        position: inherit;
        padding-top:8px
    }
    .logo {
        max-width: 100%;
    }

    .navmain-cnt {
        padding: 0px;
    }

        .navmain-cnt .navbar-nav li {
            border-bottom: 0px;
        }

            .navmain-cnt .navbar-nav li a {
                    padding: 15px 12PX !important;
            }

                .navmain-cnt .navbar-nav li a.active {
                    color: #fff !important;
                    background-color: #e92e29;
                }

    .nav-right {
        float: right;
    }

    .nav-pad-right {
        padding-right: 0px;
    }

    .btn-nav {
        margin-top: -5px;
        display: inline-block;
    }

    .navmain-cnt .dropdown-menu {
        min-width: 7rem;

    }
    .navmain-cnt .dropdown-item {
        padding: 2px 12px;
    }

    .cart-icon {
        position: relative;
        display: inline-block;
        float: none;
        padding: 10px 20px 10px 10px;
        margin-left: 6px;
        margin-right: 0px;
    }

    .en-main {
        float: right;
    }


    .en-cnt a {
        color: #616161;
        padding-top: 12px;
        font-size: 14px;
        float: left;
    }

.navmb .nav-link {
    display: block;
    /* padding: 0.5rem 1rem; */
    padding: inherit;
}

    .btn-nav:after {
        font-family: Font Awesome\ 5 Free;
        content: "";
        padding-right: 6px;
        padding-top: 2px;
        float: left;
        opacity: .8;
        transition: all .3s ease 0s;
        font-weight: 900;
    }

    .navmb {
        position: initial;
    }

    .nav-top {
        border-top: 0px;
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .user-nav-none {
        display: inline-block;
    }

    .user-profile .dropdown-menu {
        left: -20px !important;
        top: 10px !important;
    }

    .user-profile .dropdown-menu {
        top: 32px;
    }
    .user-profile {
    padding: 5px 0;
    margin-top: 5px;
    display: inline-table;

    }
    .user-mail {
    padding: 8px 15px 8px 0px;
    }
}


@media (max-width: 991px) {
    .navmb-login {
        padding: unset;
    }
}

.navbar-nav {
    margin-bottom: 9px;
  }