.signup-link .btn:focus,.btn:active {
    outline: none !important;
    box-shadow: none;
 }



h1 {
    font-size: 34px !important;
}

body {
    font-family: 'Exo 2', sans-serif;
}

.page-headings {
    font-size: 16px !important;
    font-weight: 500 !important;
    color:black !important;
}


h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
    font-weight: 600;
    color:  #000;
    /* text-transform:uppercase ; */
    font-family: 'Poppins', sans-serif;
}

.h1, .h2, .h3, .h4, .h5 {
    text-transform: none !important;
}

h2 {
    font-size: 34px;
    font-weight: 800;
}

    h2 span {
        display: block;
        color: #333;
        font-size: 14px;
        padding-top: 5px;
        font-weight: 500;
    }

h3 {
    font-size: 26px;
}

    h3 span {
        display: block;
        font-size: 14px;
        padding-top: 5px;
        font-weight: 100;
    }

h1 span, h4 span {
    display: block;
    color: #333;
    font-size: 14px;
    padding-top: 5px;
    letter-spacing: normal;
}

.h3 {
    font-family: 'Exo 2', sans-serif;
    text-transform: none;
    font-size: 24px !important;
    text-transform:none;
}

p {
    font-size: 16px;
}

/*buttons css*/

.btn-orange {
    background: #ea3028; /* Old browsers */
    background: -moz-linear-gradient(left, #ea3028 0%, #f9b043 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ea3028 0%,#f9b043 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #ea3028 0%,#f9b043 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ea3028', endColorstr='#f9b043',GradientType=1 ); /* IE6-9 */
    border-radius: 0;
    border: 0;
    color: #fff;
    font-weight: bold;
    padding: 10px;
    text-transform: uppercase;
    font-size: 16px;
}

    .btn-orange:hover {
        background-color: #e92e29;
        color: #fff;
    }


    .btn-orange:focus, .btn-orange.focus {
        border-radius: 30px;
    }

    .btn-orange.disabled, .btn-orange:disabled {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
    }


/*btn share*/
 .btn-share {
           color: #000;
    font-weight: normal;
    background-color:#fff;
    padding: 6px 16px;
    font-size: 14px;
    border-radius: 30px;
    border:1px solid #999;
    }
        .btn-share:hover {
        background-color:#f1f1f1;
        border-color:#999;
        }
        .btn-share:after {
        font-family: Font Awesome\ 5 Free;
        content: "";
        padding-top: 2px;
        opacity: .8;
        float:left;
        transition: all .3s ease 0s;
        font-weight: 900;
         padding-right:10px;
    }





.btn-round {
    border-radius: 6px !important;
    text-transform: none;
    padding:6px 20px;
    font-size:16px !important;
}

.btn-round-height{
    height:46px;
}

.btn-arrow, .btn-basket, .btn-facility {
    position: relative;
    padding: 14px;
    width: initial;
    margin-top: 0;
    font-weight: 400;
    font-size: 22px;
}


    .btn-arrow, .btn-arrow:focus, .btn-arrow:visited, .btn-basket, .btn-basket:focus, .btn-basket:visited,
    .btn-facility, .btn-facility:focus, .btn-facility:visited {
        color: #fff !important;
        background: #ea3028;
        background: -moz-linear-gradient(left, #ea3028 0%, #f9b043 100%);
        background: -webkit-linear-gradient(left, #ea3028 0%,#f9b043 100%);
        background: linear-gradient(to right, #ea3028 0%,#f9b043 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ea3028', endColorstr='#f9b043',GradientType=1 );
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
        border: 0;
        transition: all .3s ease 0s;
    }

        .btn-arrow:hover, .btn-basket:hover, .btn-facility:hover {
            box-shadow: inset 0 0 0 2px #f58f0d;
            color: #fff;
        }

        .btn-arrow:after {
            font-family: Font Awesome\ 5 Free;
            content: "";
            margin-left: 10px;
            opacity: .8;
            transition: all .3s ease 0s;
            font-weight: 900;
        }


        .btn-arrow:hover:after {
            opacity: 1;
        }


        .btn-basket:after {
            font-family: Font Awesome\ 5 Free;
            content: "";
            margin-left: 10px;
            opacity: .8;
            transition: all .3s ease 0s;
            font-weight: 900;
        }

        .btn-facility:after {
            font-family: Font Awesome\ 5 Free;
            content: "";
            opacity: .8;
            padding-right: 10px;
            float: left;
            transition: all .3s ease 0s;
            font-weight: 900;
        }


.btn-fav, .btn-fav:focus, .btn-fav:visited {
    width: initial;
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 6px;
    border: 1px solid #868585;
    background-color: #fff;
    width: 100%;
    font-size: 16px;
}

    .btn-fav:hover {
        border: 1px solid #e93e2c;
    }


    .btn-fav:after {
        font-family: Font Awesome\ 5 Free;
        content: "";
        padding-left: 6px;
        padding-top: 2px;
        opacity: .8;
        float: left;
        transition: all .3s ease 0s;
        font-weight: 900;
        
    }
    .btn-fav-after{
        border: 1px solid #e93e2c;
    }

    .btn-fav-after:after {
        font-family: Font Awesome\ 5 Free;
        content: "";
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 2px;
        opacity: .8;
        float: left;
        transition: all .3s ease 0s;
        font-weight: 900;
        color: #eb3029;
    }

.btn-gradient, .btn-edit, .btn-booking {
    padding: 14px;
    width: initial;
    margin-top: 0;
    font-weight: 400;
    font-size: 22px;
}

.btn-booking {
    width: initial;
    margin-top: 0;
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 6px;
    width: 100%;
}


    .btn-gradient, btn-gradient:focus, btn-gradient:visited, .btn-edit .btn-booking, .btn-booking:focus,
    .btn-booking:visited {
        color: #fff !important;
        background: #ea3028;
        background: -moz-linear-gradient(left, #ea3028 0%, #f9b043 100%);
        background: -webkit-linear-gradient(left, #ea3028 0%,#f9b043 100%);
        background: linear-gradient(to right, #ea3028 0%,#f9b043 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ea3028', endColorstr='#f9b043',GradientType=1 );
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
        border: 0;
        transition: all .3s ease 0s;
        border-radius: 30px;
        padding: 12px 30px;
    }

        .btn-gradient:hover, .btn-edit:hover, .btn-booking:hover {
            box-shadow: inset 0 0 0 2px #f58f0d;
            color: #fff;
        }

    .btn-edit:after, .btn-booking:after {
        font-family: Font Awesome\ 5 Free;
        content: "";
        padding-left: 6px;
        padding-top: 2px;
        opacity: .8;
        transition: all .3s ease 0s;
        font-weight: 900;
    }

    .btn-booking:after {
        content: "";
    }


.btn-white {
    padding: 14px;
    width: initial;
    margin-top: 0;
    font-weight: 400;
    font-size: 22px;
}


    .btn-white, .btn-white:focus, .btn-white:visited {
        color: #000 !important;
        background: #fff;
        font-size: 16px;
        border: 1px solid #cecece !important;
        font-weight: 400;
        text-transform: uppercase;
        border: 0;
        transition: all .3s ease 0s;
        border-radius: 30px;
        padding: 12px 30px;
    }

        .btn-white:hover {
            box-shadow: inset 0 0 0 1px #b8b8b8;
            color: #000;
        }



a:hover {
    color: #616161;
    text-decoration: none;
}

h4.card-title {
    font-family: 'Exo 2', sans-serif;
}

.card {
    margin-bottom: 10px;
}

.club-tabs .card-body {
    background-color: #f3f6fd;
}
.booking-tabs .nav-tabs {
   padding-bottom: 1px;
}
/*css common components*/
.review-box {
    max-width: 950px;
    margin: 0 auto;
}

.review-main {
    max-width: 850px;
    margin: 30px auto;
    position: relative;
    background: #ea3028;
    background: -moz-linear-gradient(left, #ea3028 0%, #f9b043 100%);
    background: -webkit-linear-gradient(left, #ea3028 0%,#f9b043 100%);
    background: linear-gradient(to right, #ea3028 0%,#f9b043 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ea3028', endColorstr='#f9b043',GradientType=1 );
    width: 100%;
}

.review-inner {
    background-color: #f5f5f5;
    padding: 20px;
    max-width: 875px;
    position: relative;
    top: 15px;
    left: 10px;
    display: table;
}

.testi-icon {
    position: absolute;
    top: -9px;
    left: 20px;
}

.testi-txt {
    float: left;
    text-align: left;
    font-size: 18px;
    text-align: center;
    padding: 30px;
}

.testi-img {
    display: none;
}

.testimonial_btn {
    background: linear-gradient(to right, #ea3028 0%,#f9b043 100%);
    color: #fff !important;
    border-radius: 0;
    border: 0px;
}

    .testimonial_btn:hover {
        background-color: #373d4b !important;
        color: #fff !important;
    }

.testi-controls {
    position: relative;
    margin:25px 0;
}


/*footer css*/
.footer-cnt {
    background-color: #333333;
    padding: 20px 0;
    text-align: center;
    color: #fff;
}

    .footer-cnt .footernav ul {
        width: 100%;
        display: table;
        text-align: center;
        padding: 0px;
    }

        .footer-cnt .footernav ul li {
            list-style: none;
            float: left;
            margin: 8px;
        }

            .footer-cnt .footernav ul li a {
                font-size: 11px;
                color: #fff;
                text-decoration: none;
            }

                .footer-cnt .footernav ul li a:hover {
                    color: #ddd;
                }

.social-cnt {
    width: 100%;
    text-align: left;
    font-size: 12px;
}

    .social-cnt ul {
        width: 100%;
        text-align: center;
        padding: 0px;
        display: table;
        margin: 0;
    }

        .social-cnt ul li {
            list-style: none;
            float: right;
            margin: 8px;
        }

        .social-cnt i {
            font-size: 16px;
            background: #6b6767;
            width: 33px;
            height: 33px;
            line-height: 16px;
            border-radius: unset;
            color: #fff;
            padding: 8px;
            transition: ease all .4s;
        }

        .social-cnt i:hover{
            background: #ea3329;
        }

.social-txt {
    padding-left: 6px;
    text-align: right;
}

@media (max-width: 991px){
    .social-cnt ul li{
        float: left;
    }

    .social-txt{
        text-align: left;
    }
}

/*top form*/

/* .coach-form {

} */

/* .coach-form-top {
    background-image: url('../images/form-bg-top.webp');
    background-repeat: no-repeat;
    background-position: top;
    height: 4px;
    margin-top: 10px;
} */

.coach-form-mid {
    -webkit-box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.15);
    padding: 20px 20px;
    background: #fff;
    border-top: 5px solid #e62f2d;
    margin-top: 10px;
}

    .coach-form-mid .form-heading {
        font-size: 20px !important;
        font-weight: bold;
        text-transform: uppercase;
        color: #000;
        margin: 0px;
    }

        .coach-form-mid .form-heading span {
            display: block;
            color: #999999;
            font-weight: normal;
            font-size: 14px;
            padding-bottom: 15px;
            text-transform: none;
        }

.coach-form-bottom {
    background-position: center top;
    background-image: url('../images/ch-form-bg.webp');
    background-repeat: repeat-y;
    padding: 10px 20px 100px;
}

.coach-form .form-control {
    height: 44px !important;
    border-radius: 0px;
}

.form-control:focus {
    border-color: #f4823a;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(88, 88, 88, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(189, 190, 191, 0.6);
}

.signup-popup {
    -webkit-box-shadow: 1px 1px 13px 0px rgba(214,207,214,1);
    -moz-box-shadow: 1px 1px 13px 0px rgba(214,207,214,1);
    box-shadow: 1px 1px 13px 0px rgba(214,207,214,1);
    width: 100%;
    background-color: #FFF;
}

.signup-inner {
    padding: 40px 20PX;
}

    .signup-inner .form-heading {
        font-size: 20px !important;
        font-weight: bold;
        text-transform: uppercase;
        color: #000;
        margin: 0px;
        font-family: 'Exo 2', sans-serif;
        text-align: center;
        padding-bottom: 30PX;
    }

        .signup-inner .form-heading span {
            display: block;
            color: #999999;
            font-weight: normal;
            font-size: 14px;
            padding-bottom: 15px;
            text-transform: none;
        }

.signup-popup img {
    object-fit: fill;
  
    width: 100%;
}
.signup-height {
    width: 100%;
    height:100%;
}

.form-control {
    height: 44px;
    border-radius: 0px;
}

.form-sept {
    width: 200px;
    text-align: center;
    border-bottom: 1px solid #cccbcb;
    line-height: 0.1em;
    margin: 25px auto;
}

    .form-sept span {
        background: #fff;
        padding: 0 10px;
        color: #afafaf;
    }
/*social media buttons*/
.fb-btn, .google-btn {
    background-color: #355c9a;
    border: none;
    /* padding: 6px 6px 0; */
    border-radius: 25px;
    width: 100%;
    color: #fff;
    height: 45px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 10px;
}

.google-btn {
    background-color: #f14336;
}

.fb-btn-icon, .google-btn-icon {
    float: left;
    background-color: #fff;
    padding: 8px;
    color: #355c9a;
    border-radius: 20px;
    width: 33px;
    position: relative;
    top: -1px;
    left: 0px;
}

.google-btn-icon {
    color: #f14336;
}

.common-header {
    background-image: url('../images/bg01.webp');
    background-repeat: repeat-x;
    padding: 30px 0;
    text-align: center;
}

.hero-cnt {
    color: #999999;
}

    .hero-cnt label {
        color: #000;
    }

    .hero-cnt ul {
        width: 100%;
        margin: 0px;
        padding-left: 20px;
    }

        .hero-cnt ul li {
            padding-bottom: 10px;
            list-style: none;
            padding-top: 10px;
            text-indent: -1.28571429em;
            PADDING-LEFT: 15PX;
        }

            .hero-cnt ul li:before {
                content: "\2714\0020";
                color: #ea3329;
                padding-right: 5px;
            }

    .hero-cnt p {
        padding-top: 15px;
    }

.hero-heading {
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
    font-size: 34px;
    font-family: 'Exo 2', sans-serif;
}

    .hero-heading span {
        display: block;
        color: #999999;
        font-size: 14px;
        padding-top: 5px;
        letter-spacing: normal;
        text-transform: none;
        font-weight: normal;
    }

.hero-titles {
    font-size: 26px !important;
    color: #000;
    text-transform: none;
    font-weight: 500;
    font-family: 'Exo 2', sans-serif;
    padding-top: 15px;
}

/*collapse css*/

.tazman-collapse {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
}

    .tazman-collapse .card {
        border-radius: 0px;
        margin-bottom: 10px;
    }

    .tazman-collapse .collapse-heading {
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
    }

    .tazman-collapse .card-header {
        text-align: left;
        background-color: rgb(255, 255, 255);
        color: #999;
        border: 1px solid #ececec;
    }

        .tazman-collapse .card-header:after {
            font-family: 'Font Awesome\ 5 Free';
            content: "\f078";
            font-weight: 900;
            position: absolute;
            right: 10px;
            top: 15px;
        }

        .tazman-collapse .card-header.active:after {
            font-family: 'Font Awesome\ 5 Free';
            content: "\f077";
            font-weight: 900;
            position: absolute;
            right: 10px;
            top: 15px;
        }

        .tazman-collapse .card-header.btn:focus, .btn.focus {
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgb(255, 255, 255);
        }

        .tazman-collapse .card-header.active {
            background-color: #e22122;
            color: #FFF;
        }

        .tazman-collapse .card-header:hover {
            background-color: #e22122;
            color: #FFF;
        }

.contactbg {
    -webkit-box-shadow: 2px 2px 20px -10px rgba(184,182,184,1);
    -moz-box-shadow: 2px 2px 20px -10px rgba(184,182,184,1);
    box-shadow: 2px 2px 20px -10px rgba(184,182,184,1);
    padding: 20px 30PX;
    margin-top: 40PX;
}

    .contactbg .address, .email, .phone, .fax {
        position: relative;
        padding-left: 34px;
        display: inline-block;
        padding-top: 5px;
        width: 100%;
    }

        .contactbg .address:before {
            font-family: 'Font Awesome\ 5 Free';
            content: "";
            left: 0;
            position: absolute;
            top: 2px;
            color: #e93e2c;
            font-weight: 900;
            font-size: 24px;
        }

    .contactbg .email:before {
        font-family: 'Font Awesome\ 5 Free';
        content: "";
        left: 0;
        position: absolute;
        top: 2px;
        color: #e93e2c;
        font-weight: 900;
        font-size: 22px;
    }

    .contactbg .phone:before {
        font-family: 'Font Awesome\ 5 Free';
        content: "";
        left: 0;
        position: absolute;
        top: 2px;
        color: #e93e2c;
        font-weight: 900;
        font-size: 22px;
    }

    .contactbg .fax:before {
        font-family: 'Font Awesome\ 5 Free';
        content: "";
        left: 0;
        position: absolute;
        top: 2px;
        color: #e93e2c;
        font-weight: 900;
        font-size: 22px;
    }

/*profile css*/

.profile-main {
    margin-top: 30px;
    text-align: center;
}



/*about page css*/

.abt-main-cnt {
    background-image: url('../images/about-bg.webp');
    background-repeat: no-repeat;
    background-position: top left;
    width: 100%;
    padding: 30px 0;
}

    .abt-main-cnt ul {
        width: 100%;
        margin: 0px;
        padding-left: 20px;
    }

        .abt-main-cnt ul li {
            padding-bottom: 10px;
            list-style: none;
            padding-top: 10px;
            text-indent: -1.28571429em;
            PADDING-LEFT: 15PX;
        }

            .abt-main-cnt ul li:before {
                content: "\2714\0020";
                color: #ea3329;
                padding-right: 5px;
            }

.img-padding {
    padding: 30px 55px;
}

.abtinfo {
    padding: 20px 15px;
    text-align: center;
}

    .abtinfo h3 {
        color: #ea3329;
        padding-top: 10px;
        text-transform: none;
        font-family: 'Exo 2', sans-serif !important;
        font-size: 30px;
    }

.abt-mid-cnt {
    background-image: url(../images/abtmid-bg.webp);
    background-repeat: repeat-x;
    background-position: center top;
    padding: 60px 0 0;
    width: 100%;
    color: #fff;
    background-color: #f2f2f2;
    text-align: center;
}

    .abt-mid-cnt h3 {
        color: #fff !important;
    }


.abt-detail {
    -webkit-box-shadow: 6px 6px 14px -6px rgba(224,224,224,1);
    -moz-box-shadow: 6px 6px 14px -6px rgba(224,224,224,1);
    box-shadow: 6px 6px 14px -6px rgba(224,224,224,1);
    background-color: #fff;
    width: 100%;
    margin-top: 20px;
    color: #000;
}

    .abt-detail .abt-detail-inner {
        padding: 20px;
    }

/*pricing css*/

.pricing-cnt {
    width: 100%;
}

    .pricing-cnt .nav-tabs, .club-tabs .nav-tabs {
        border-bottom: 4px solid #ec402c;
    }

        .pricing-cnt .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link,
        .club-tabs .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
            color: #fff;
            background-color: #eb3029;
            border: none !important;
            border-radius: 0px;
            text-transform: none;
            padding: 10px 12px;
           
        }
        .club-tabs .nav-tabs .nav-link {
            font-size:12px;
        }
        .club-tabs .nav-tabs {
            padding-bottom:-1px;
        }
     

    .pricing-cnt .tab-content,
    .club-tabs .tab-content {
        background-color: #f5f5f5;
        padding: 40px 15px;
        margin-bottom: 25px;
    }

    .pricing-cnt .nav-tabs .nav-link {
        border: 1px solid #d0cfcf;
        background-color: #f5f5f5;
        color: #000;
        border-bottom: 0px;
        padding: 11px 18px;
        text-transform: uppercase;
        border-radius: 0px;
    }

.pricebox {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: #fff;
    padding: 20px;
}

    .pricebox h4 {
        color: #000;
    }

    .pricebox .price-top {
        background-color: #f5f5f5;
        text-align: center;
        padding: 20px 15px;
        color: #eb3029;
        font-size: 36px;
        font-weight: bold;
    }

        .pricebox .price-top span {
            color: #000;
            font-weight: normal;
            font-size: 18px;
        }

    .pricebox ul {
        padding: 20px 10px 0 10px;
    }

        .pricebox ul li {
            padding-bottom: 10px;
            list-style: none;
            padding-bottom: 5px;
            text-indent: -1.28571429em;
            PADDING-LEFT: 15PX;
        }

            .pricebox ul li:before {
                content: "\2714\0020";
                padding-right: 5px;
            }

.price-colorbox {
    color: #fff !important;
    background: #ea3028;
    background: -moz-linear-gradient(left, #ea3028 0%, #f9b043 100%);
    background: -webkit-linear-gradient(left, #ea3028 0%,#f9b043 100%);
    background: linear-gradient(to right, #ea3028 0%,#f9b043 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ea3028', endColorstr='#f9b043',GradientType=1 );
    padding: 30px 20px;
    border-radius: 6px;
    margin: 15px 0;
}

    .price-colorbox h4 {
        color: #fff;
    }

    .price-colorbox .price-top {
        background: linear-gradient(to right, #db342b 0%,#e6a040 100%);
        text-align: center;
        padding: 20px 15px;
        color: #fff;
        font-size: 36px;
        font-weight: bold;
    }

        .price-colorbox .price-top span {
            font-weight: normal;
            font-size: 18px;
        }

    .price-colorbox ul {
        padding: 20px 10px 0 10px;
    }

        .price-colorbox ul li {
            padding-bottom: 10px;
            list-style: none;
            padding-bottom: 5px;
            text-indent: -1.28571429em;
            PADDING-LEFT: 15PX;
        }

            .price-colorbox ul li:before {
                content: "\2714\0020";
                padding-right: 5px;
            }

.pricing-cnt .nav-tabs .nav-item, .club-tabs .nav-tabs .nav-item {
    width:auto;
}

/*section css*/
.section-lg {
    padding: 30px 0;
}

.section-md {
    padding: 20px 0 0;
}

.section-sm {
    padding: 30px 0;
}

/*gear box*/
.box {
    position: relative;
}

.gear-box {
    display: block;
    width: 100%;

    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-top: 15px;
    position: relative;
    margin-bottom: 15px;
}

    .gear-box .heart:after {
        font-family: 'Font Awesome\ 5 Free';
        content: "";
        position: absolute;
        top: 6px;
        color: #cac4c4;
        right: 8px;
        font-size: 18px;
    }

    .gear-box .header {
        text-align: center;
        padding: 10px;
    }

    .gear-box .footer {
        display: block;
        /*position: absolute;*/
        /*bottom: 10px;*/
        /*left: 10px;*/
        text-align: center;
    }

        .gear-box .footer .price {
            color: #ea3429;
            font-weight: bold;
            font-size: 20px;
        }

            .gear-box .footer .price span {
                font-size: 12px;
                color: #000;
                display: block;
                font-weight: normal;
            }

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #000;
}

    .overlay .price {
        color: #fff;
        font-weight: bold;
        font-size: 20px;
        padding-top: 15px;
    }

        .overlay .price span {
            font-size: 12px;
            display: block;
        }

.box.hover .overlay, .box:hover .overlay {
    opacity: 1;
}

.text {
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 50%;
left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
  
    padding: 0px;
    margin: 0 auto;
    max-width: 150px;
}

.gear-btn {
    width: 100%;
    font-size: 14px !important;
    font-weight: normal;
}

.sports-icon-main {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.sports-lg-icon {
    background-image: url('../images/sports_lg_icons.webp');
    width: 135px;
    height: 135px;
    display: inline-block;
    margin: 16px 10px 16px 20px;
    border: 1px solid #ddd;
}

    .sports-lg-icon:hover, .sports-lg-icon.active {
        background-position-y: 135px;
    }

    .sports-lg-icon-main .basketball {
    background-position-x: 2025px;
}

.sports-lg-icon-main .hockey {
    background-position-x: 1890px;
}

.sports-lg-icon-main .icehockey {
    background-position-x: 1755px;
}

.sports-lg-icon-main .batminton {
    background-position-x: 1620px;
}
.sports-lg-icon-main .baseball {
    background-position-x: 1485px;
}

.sports-lg-icon-main .volleyball {
    background-position-x: 1350px;
}


.sports-lg-icon-main .cricket {
    background-position-x: 1215px;
}

.sports-lg-icon-main  .boxing {
    background-position-x: 1080px;
}

.sports-lg-icon-main .table-tennis {
    background-position-x: 945px;
}

.sports-lg-icon-main  .tennis {
    background-position-x: 810px;
}

.sports-lg-icon-main .golf {
    background-position-x: 675px;
}

.sports-lg-icon-main  .rugby {
    background-position-x: 540px;
}

.sports-lg-icon-main .bowling {
    background-position-x: 405px;
}

.sports-lg-icon-main .swiming {
    background-position-x: 270px;
}

.sports-lg-icon-main .car-racing {
    background-position-x: 135px;
}

/*product details css*/
.product-details {
    width: 100%;
    position: relative;
    padding: 10px;
}

    .product-details a {
        font-size: 24px;
        font-weight: bold;
        color: #000;
        text-decoration: none;
    }

    .product-details .smlink {
        color: #999;
        font-size: 16px;
        font-weight: normal;
    }

    .product-details .ratebox {
        display: inline-block;
        margin-top: 10px;
    }

        .product-details .ratebox .raterater-layer {
            margin-top: 2px;
        }

    .product-details .review-links {
        display: inline-block;
        padding-left: 10px;
    }

        .product-details .review-links a {
            font-size: 12px;
            color: #999;
            font-weight: normal;
            padding-left: 5px;
            padding-right: 5px;
        }

    .product-details .ps {
        margin-top: 25px;
        color: #999;
        font-size: 18px;
        line-height: normal;
    }

        .product-details .ps span {
            display: block;
            font-size: 26px;
            color: #ea3329;
            text-decoration: none;
            font-weight: 800;
        }

    .product-details .award {
        margin-top: 25px;
        font-size: 16px;
        color: #000;
        padding-bottom: 20px;
    }

        .product-details .award span {
            font-size: 20px;
            color: #ea3329;
        }

            .product-details .award span:before {
                font-family: Font Awesome\ 5 Free;
                content: "";
                margin-left: 10px;
                padding-right: 10px;
                transition: all .3s ease 0s;
                font-weight: 900;
            }


/*product tabs*/

.product-tabs {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
}

    .product-tabs .nav-tabs {
        border-bottom: 4px solid #eb372a;
    }

    .product-tabs .tab-content {
        display: none;
    }

    .product-tabs .card-body {
        padding: 0px;
    }

.section-md .product-tabs .card-body {
    padding: 10px 12px;
}

.product-tabs .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #e92e29;
    color: #fff;
    background-color: #eb372a;
}

.product-tabs .nav-tabs .nav-link {
    border: none;
    font-weight: bold;
    padding: 10px 40px;
    border: 1px solid #ddd;
}

    .product-tabs .nav-tabs .nav-link .nav-active {
        background-color: #000;
    }

.product-tabs .card {
    margin-bottom: 6px;
    border-radius: 0px;
}


.product-heading {
    text-transform: none;
    color: #000;
    font-size: 18px;
    font-family: 'Exo 2', sans-serif;
    letter-spacing: normal;
    padding-bottom: 6px;
}

.rt-box {
    padding: 40px 20px;
    box-shadow: 2px 3px 21px -10px rgba(204,204,204,1);
    background-color: #fff;
    text-align: center;
    font-size: 18px;
    display: inline-block;
    margin-top: 15px;
}

    .rt-box span {
        color: #ddd;
        font-size: 14px;
        display: block;
    }

.stars {
    display: inline-block;
    padding-top: 75px;
}

.pro-review {
    padding: 5px 10px 10px 0;
    display: inline-table;
    color: #999;
}

.progress-cnt {
    padding-top: 30px;
    padding-bottom: 30px;
}

    .progress-cnt .progress {
        height: 9px;
        margin-top: 10px;
    }

.w-15 {
    width: 15% !important;
}

/*inner common header*/
.inner-header-top {
    background-color: #f3f6fd;
    padding:8px 0 40px;
    width: 100%;
    text-align: center;
}

.provider-activity .inner-header-top,
.provider-messages .inner-header-top {
    padding: 30px 0 80px;
}

.inner-top-heading {
    font-family: 'Exo 2', sans-serif;
    text-transform: none;
    font-size: 24px !important;
    color: #000;
    padding-top:10px;
    text-transform: none;
    padding-bottom: 10px !important;
}


    .inner-top-heading span {
        color: #999;
        padding-top: 0;
    }

.inner-main-cnt {
    background-color: #fff;
    bottom: 0;
    border-radius: 6px;
    padding: 20px;
    width: 100%;
    box-shadow: 2px 3px 21px -10px rgba(204,204,204,1);
    margin-top: -60px;
    margin-bottom: 40px;
    padding-top: 0px;
    display: table;
}

.user-social .inner-main-cnt {
    display: block
}

/*inner common search*/
.inner-search-bar {
    border-bottom: 0;
    width: 100%;
    padding: 0 15px;
}

.search-bar-padding {
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 20px;
}

.inner-search-bar .form-control {
    height: auto !important;
}

.inner-search-bar .form-check {
    padding-left: 0px;
}

.gear-search {
    position: relative;
    display: block;
    border: 1px solid #f9fafb;
    width: 100%;
}


    .gear-search:before {
        font-family: 'Font Awesome\ 5 Free';
        content: "";
        position: absolute;
        top: 6px;
        color: #db342b;
        font-weight: 700;
        right: 10px;
    }

.input-div {
    position: relative;
    display: block;
    border: 1px solid #f9fafb;
    width: 100%;
}

    .input-div:before {
        font-family: 'Font Awesome\ 5 Free';
        content: "";
        position: absolute;
        top: 6px;
        color: #d0d1d2;
        font-weight: 700;
        right: 10px;
    }

.sort {
    position: relative;
    padding-right: 10px;
    margin-top: -5px;
    display: none;
}

    .sort span {
        font-weight: 400;
        color: #999;
        line-height: normal;
        font-size: 13px;
        padding-left: 20px;
    }

    .sort:before {
        font-family: 'Font Awesome\ 5 Free';
        content: "";
        position: absolute;
        top: 2px;
        color: #d0d1d2;
        font-weight: 700;
    }

.reviews {
    margin: 0 auto;
}


    .reviews h5 {
        font-family: 'Exo 2', sans-serif;
        text-transform: none;
        color: #000;
    }

.review-item {
    padding: 20px 0 30px 0;
    border-bottom: 1px solid #dde2e8;
}

    .review-item.lst {
        border-bottom: 0px !important;
    }



    .review-item .review-text {
        font-size: 16px;
        font-weight: normal;
        margin-top: 5px;
        color: #999;
    }

    .review-item .reviewer {
        width: 100px;
        height: 100px;
        border: 1px solid #cecece;
        margin-bottom: 20px;
    }

/****Rating Stars***/
.raterater-bg-layer {
    color: rgba( 0, 0, 0, 0.25 );
}

.raterater-hover-layer {
    color: rgba( 254, 211, 36, 1 );
}

    .raterater-hover-layer.rated { /* after the user selects a rating */
        color: rgba( 254, 211, 36, 1 );
    }

.raterater-rating-layer {
    color: rgba( 254, 211, 36, 1 );
}

.raterater-outline-layer {
    color: rgba( 0, 0, 0, 0.25 );
}

/* don't change these - you might break something.. */
.raterater-wrapper {
    overflow: visible;
}

.software .raterater-wrapper {
    margin-top: 4px;
}

.raterater-layer,
.raterater-layer i {
    display: block;
    position: absolute;
    overflow: visible;
    top: 0px;
    left: 0px;
}

.raterater-hover-layer {
    display: none;
}

    .raterater-hover-layer i,
    .raterater-rating-layer i {
        width: 0px;
        overflow: hidden;
    }

.venue-top-search {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #e92e29;
    width: 100%;
}

    .venue-top-search .input-group-text {
        color: #e92e29;
        background-color: #ffffff;
        border: none;
    }

    .venue-top-search .border-right {
        border-right: 1px solid #ccc;
    }

    .venue-top-search .venuebtn {
        width: 100%;
    }

.shadow-box {
    background-color: #fff;
    bottom: 0;
    border-radius: 6px;
    padding: 20px;
    width: 100%;
    box-shadow: 0px 0px 22px 0px rgba(204,204,204,1);
    margin-top: -18px;
    margin-bottom: 40px;
    padding-top: 10px;
    display: table;
    padding-bottom: 10px;
}

.venue-list .container,
.facility-list .container,
.equipment-list .container {
    padding: 0;
}

.notification-cnt {
    max-width: 950px;
    width: 100%;
    margin: 0 auto;
    box-shadow: 2px 3px 21px -10px rgba(204,204,204,1);
    margin-top: -30px;
    margin-bottom: 40px;
    display: table;
    padding: 20px;
    border-radius: 6px;
    background-color: #fff;
}

    .notification-cnt ul {
        background-color: #f3f6fd;
        width: 100%;
        display: table;
        padding: 0px;
        margin-top: 20px;
    }

        .notification-cnt ul li {
            border-bottom: 1px solid #e5e7ec;
            padding: 5px 10px;
        }

            .notification-cnt ul li .date {
                text-align:left;
                font-size: 14px;
                padding-top: 6px;
                padding-left: 10px;
            }

            .notification-cnt ul li .calendar-red:after, .notification-cnt ul li .calendar-green:after,
            .notification-cnt ul li .partner:after, .notification-cnt ul li .email-blue:after {
                font-family: Font Awesome\ 5 Free;
                content: "";
                margin-left: 10px;
                opacity: .8;
                transition: all .3s ease 0s;
                font-weight: 900;
                font-size: 22px;
                color: #ea3329;
                
            }
            .calendar-red {
                text-align: right;
            }

            .notification-cnt ul li .calendar-green:after {
                content: "";
                color: #23bf8a;
            }

            .notification-cnt ul li .partner:after {
                content: "";
                font-size: 24px;
                color: #faa227;
            }

            .notification-cnt ul li .email-blue:after {
                content: "";
                color: #29caf4;
            }

            .notification-cnt ul li .img {
                float: left;
                margin-right: 10px;
                width: 20%;
            }

            .notification-cnt ul li .title {
                font-size: 12px;
                font-weight: 600;
                color: #000;
                display: table;
                margin-top: 8px;
                line-height: normal;
            }

                .notification-cnt ul li .title span {
                    color: #999;
                    font-size: 12px;
                }

ul li {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.sm-list {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd !important;
    margin-top: 25px;
    text-align: center;
}

.sm-list .slheader {
        width: 100%;
        margin-bottom: 10px;
        position: relative;
        text-align: center;
        position: relative;
    }
 .sm-list:hover {
        background-color: #fafafa;
    }

.sm-list .slheader .slheader-img {
        border: 1px solid #ddd;
        border-radius: 75px;
        max-width: 75%;
        margin: 0 auto;
    }

.user-social .sm-list .slheader .slheader-img {
        width: max-content;
        border-radius: 50%;
    }

.sm-list .slbody {
        font-weight: bold;
        font-size: 13px;
        color: #000;
        padding-top: 10px;
        display: block;
    }

.sm-list .slbody .txt {
            display: block;
            font-weight: 400;
            color: #999;
            line-height: normal;
            font-size: 12px;
        }

.sm-list .slbody .sladdress {
            position: relative;
            display: block;
        }

.sm-list .slbody .sladdress:before {
                font-family: 'Font Awesome\ 5 Free';
                content: "";
                position: absolute;
                top: 2px;
                color: #e92e29;
                font-weight: 700;
            }

.sm-list .slbody .sladdress span {
                font-weight: 400;
                color: #999;
                line-height: normal;
                font-size: 12px;
                padding-left: 16px;
            }

.sm-list .slfooter {
        padding: 10px 0;
    }

 .sm-list .cstars {
        width: 90px;
        margin: 8px auto;
    }

.profile-bg .table thead th {
    border-bottom: none;
}

.profile-bg .table th, .table td {
    border-top: none;
    color: #000;
}

.profile-bg tr {
    font-size: 12px;
}

.profile-bg {
    background-color: #fff;
    bottom: 0;
    border-radius: 6px;
    width: 100%;
    box-shadow: 2px 3px 21px -10px rgba(204,204,204,1);
    margin-top: -26px;
    margin-bottom: 40px;
    display: table;
}
.profile-detail-cnt .step .step-item a {
        font-size: 11px;
}

.profile-detail-cnt {
    padding:18px;
    width: 100%;
    color: #999999;
}
.ant-upload.ant-upload-drag .ant-upload {
   padding:4px; 
  
}
    .profile-detail-cnt h2, .players-detail-cnt h3, .profile-detail-cnt h3 {
        text-transform: none;
        color: #000 !important;
        font-weight: 600;
        font-size: 18px !important;
        font-family: 'Exo 2', sans-serif;
        letter-spacing: normal;
        margin-bottom: 15px;
    }
        .profile-detail-cnt h3 span {
                color: #999;
    font-weight: normal;
    line-height: normal;
    padding: 0px;
}
     .icons-margin {
    margin: 10px 2px !important;
}   

        .profile-detail-cnt h2:after {
            content: "";
            display: block;
            border-bottom: 4px solid #e92e29;
            width:10%;
            position: relative;
            bottom: -5px; /* your padding + border-width */
        }
        .profile-cnt h3 span {
            color:#999;
            font-weight:normal;
            line-height:normal;
            padding:0px;
        }

        .profile-box {
    padding: 20px 0;
    text-align: center;
    width: 100%;
    display: table;
}

    .profile-box .header {
        width: 100%;
        margin-bottom: 10px;
    }

    .profile-box .body {
        font-weight: bold;
        font-size: 18px;
        color: #000;
        padding-top: 10px;
        display: block;
        line-height:normal;
    }
        .profile-box .body .div {
          font-size:14px;
          color:#999;
          padding-bottom:20px;
          font-weight:normal;
          display:block;
        }

        .profile-box .body .address, .profile-box .body .pin {
            position: relative;
            display: block;
        }
        .profile-box .body a {
        font-weight: bold;
        font-size: 14px;
        }
            .profile-box .body a span {
                font-size:14px;
          color:#999;
          font-weight:normal;
          display:block;
            }

            .profile-box .body .address:before, .profile-box .body .pin:before {
                font-family: 'Font Awesome\ 5 Free';
                content: "";
                position: absolute;
                top: 2px;
                color: #e92e29;
                font-weight: 700;
            }

            .profile-box .body .pin:before {
                content: "";
            }

            .profile-box .body .address span, .profile-box .body .pin span {
                font-weight: 400;
                color: #999;
                line-height: normal;
                font-size: 16px;
                padding-left: 20px;
            }

    .profile-box .footer {
        margin-top: 20px;
        border-top: 1px solid #ddd;
        width: 100%;
        padding-top: 10px;
    }

        .profile-box .footer .connections {
            color: #e92e29;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            display: inline-table;
            line-height: normal;
            margin: 10px;
        }

            .profile-box .footer .connections span {
                font-weight: 400;
                color: #999;
                font-size: 12px;
                display: block;
            }

/* .gear-detail-cnt {

} */
.gear-detail-cnt .gear-title {
    font-size:16px;
    font-weight:bold;
}
    .gear-detail-cnt .gear-title span {
    font-size:14px;
    color:#999;
    }
.gear-detail-cnt .gear-cart-img {
    width:80%;
    text-align:center;
    border:1px solid #ddd;
}
.gear-detail-cnt .gear-details {
    font-size:14px;
    text-align:center;
    line-height:normal;
    margin-top:20PX;
        font-weight: 600;
}
.gear-detail-cnt .gear-details span {
       font-size:12px;
       color:#000;
       display:block;
       padding-top:2PX;
       font-weight:normal;
    }
.gear-detail-cnt .gear-price {
    font-size:18px;
    font-weight:bold;
    line-height:normal;
    margin-top:20PX;
    text-align:center;
    color:#e92e29;
}
    .gear-detail-cnt .gear-price span {
    font-size:14px;
    display:block;
    color:#999;
    text-decoration:line-through;
    font-weight:normal;
    }
    .gear-detail-cnt .gear-status {
        font-size:18px;
        font-weight:bold;
        line-height:normal;
         margin-top:20PX;
         text-align:center;
    }
        .gear-detail-cnt .gear-status span {
            font-size:11px;
            color:#999;
            display:block;
            font-weight:normal;
        }

    .gear-detail-cnt .gear-footer {
        border-top:1px solid #dfdfdf;
        padding:6px 8px 0;
        margin-top:30px;
    }
        .gear-detail-cnt .gear-footer .lft, .gear-detail-cnt .gear-footer .rt {
           font-size:14px;
           font-weight:bold;
           line-height:normal;
           padding-top:5px
        }
        .gear-detail-cnt .gear-footer .rt {
            color:#e92e29;
        }
            .gear-detail-cnt .gear-footer .lft span, .gear-detail-cnt .gear-footer .rt span {
            color:#999;

            }
            .gear-detail-cnt .success, .gear-detail-cnt .cancel {
              color:#50a815;
              font-weight:bold;
              font-size:18px;
            }
            .gear-detail-cnt .cancel {
                color:#ea9f2a;
            }

/*product popup*/

.produt-popup {
    max-width:750px;
    margin:0 auto;
    border:1px solid #f3f6fd;
}
.produt-popup .input-div:before {
    font-family: 'Font Awesome\ 5 Free';
    content: "";
    position: absolute;
    top: 9px;
    color: #d0d1d2;
    font-weight: 700;
    right: 22px;
}

.btn-wt {
    background-color:#fff;
    padding: 6px 24px;
    font-size: 14px;
    border-radius: 30px;
    color:#000;
    border: 1px solid #cecbcb;
}
.btn-settings, .btn-edit-profile, .btn-yellow {
    background: #ea3028; /* Old browsers */
    background: -moz-linear-gradient(left, #ea3028 0%, #f9b043 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ea3028 0%,#f9b043 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #ea3028 0%,#f9b043 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ea3028', endColorstr='#f9b043',GradientType=1 ); /* IE6-9 */
    border: 0;
    color: #fff;
    font-weight: normal;
    padding: 6px 24px;
    font-size: 14px;
    border-radius: 30px;
}
.btn-edit-profile {
margin:4px;
}

    .btn-settings:after, .btn-edit-profile:after {
        font-family: Font Awesome\ 5 Free;
        content: "";
        padding-top: 2px;
        opacity: .8;
        transition: all .3s ease 0s;
        font-weight: 900;
    }

    .btn-edit-profile:after {
        content: "";
        float:left;
        padding-right:10px;
    }

    /* .btn-settings:focus, .btn-settings.focus, .btn-edit-profile:focus .btn-edit-profile.focus {
    } */

    .btn-settings:hover, .btn-edit-profile:hover, .btn-yellow:hover {
        color: #fff;
        background-color: #e92e29;
        border-color: #f9b043;
    }
    .pm-0 {
        padding:0px;
        margin:0px;
    }
    .popup-skill {
        width: 100%;
    }
    .popup-skill .skill-cnt {
        margin-top: 24px !important;
    }
    .popup-skill .step .step-item a {
        font-size: 10px !important; 
    }
    @media (max-width:767px) { 
         .mb_mt-2{
             margin-top: 4px;
         }
        .equipment-heading {
            font-size:16px !important;
        }
      
        .equipment-heading {
            font-size: 20px;
            text-transform: none;
            font-weight: 400;
            padding: 10px;
        }
        .btn-mobile {
            width:100%;
        }
        .mt30 {
            margin-top:-30px !important;
        }
        .tmb-center {
            text-align: center;
        }
        .mt3 {
            margin-top: 3px;
        }
        .pl0 {
            padding-left:0px !important;
        }
        .sr-left {
            margin-left:0px;
        }
        .coach-skill-left {
            margin-left: -55px;
        }
        .slfooter h6 {
           font-size:12px;
        }

        
    }
    .cross-btn {
        position: absolute;
        z-index: 1;
        right: 10px;  
    }
    .pb-50 {
        padding-bottom: 50px !important;
    }
    @media (max-width: 768px) and (min-width: 600px) {
        
       
        
        .venue-top-search .form-inline .form-control {
            border:none;
        
        }
        .venue-top-search .input-group > .custom-select:not(:first-child) {
            border:none;
        }
    }
    .date-box-picker {
        /* border: 1px solid #e1e1e1; */
        padding: 6px 0;
        width: 100%;
        display: table;
    }

    .date-loader{
        margin-top: 12px;
        padding-left: 0px;
    }

.no-ant-input-border .ant-input {
    border: none
}
.coach-form-mid .checkbox {
    padding-left:20px;
}



@media (max-width: 1800px) and (min-width: 1200px) {
    .dk_dflex{
        display:flex
    }
    .pad-22 {
        padding:10px 25px !important;
    }

    .equipment-heading {
        font-size: 20px !important;
        text-transform: none !important;
        font-weight: 400;
        padding: 10px;
    }
    
    .sr-left {
        margin-left:5px;
    }
    .profile-detail-cnt {
        padding: 28px;
    }
   
    .coach-form-mid {
        padding: 20px 30px 30px 30px;
    }
    
    .text {
        color: white;
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        margin:auto;
        max-width: none;
    }
    .pricing-cnt .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link,
    .club-tabs .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        padding: 12px 40px;
    }
    .club-tabs .nav-tabs .nav-link {
        border-right: 1px solid #d0cfcf !important;
        padding:11px !important;
        font-size:15px;
    }
    .club-tabs .nav-tabs .nav-item, .pricing-cnt .nav-tabs .nav-item {
        width: 100%;
    }
    .date-box-picker {
        max-width: 320px;
    }
    .ant-calendar-range-picker-input {
        width: 42%;
    }
    .inner-header-top {
        padding: 30px 0 80px;
    }

    .shadow-box {
        margin-top: -60px;
        margin-bottom: 40px;
    }

    .gear-detail-cnt .gear-details {
        font-size: 13px;
         margin-top:30PX;
    }
        .gear-detail-cnt .gear-details span {
            font-size: 12px;
        }
    .gear-detail-cnt .gear-price {
    margin-top:30PX;
    }
    .gear-detail-cnt .gear-status {
    margin-top:28px;
    }
    .gear-detail-cnt .gear-padding {
        padding:0px;
    }
    .inner-header-top {
        text-align: unset;
    }

    .search-bar-padding {
        padding-top: 20px;
    }

    .inner-search-bar {
        border-bottom: 1px solid #ccc;
        width: 100%;
        padding-bottom: 10px;
    }

    .sort {
        display: block;
    }

    h1 {
        font-size: 44px;
    }


    h2 {
        font-size:40px !important;
    }


    p {
        font-size: 16px;
    }

    .review-inner {
        background-color: #f5f5f5;
        padding: 20px;
        max-width: 830px;
        position: relative;
        top: 26px;
        left: 25px;
        display: table;
        width: 100%;
    }

    .testi-icon {
        position: absolute;
        top: -9px;
        left: 20px;
    }

    .testi-txt {
        float: left;
        padding: 40px 0 45px 30px;
        text-align: left;
        font-size: 18px;
        max-width: 500px;
    }

    .testi-img {
        position: absolute;
        top: -26px;
        right: -45px;
        display: block;
    }

    .testi-controls {
        float: right;
        margin-top: -30px;
        position: relative;
        padding-right: 44px;
    }

    .footer-cnt .footernav ul {
        padding-top: 12px;
    }

    .social-cnt {
        width: 350px;
        float: right;
        text-align: left !important;
    }

        .social-cnt ul li {
            margin: 8px;
        }

        .social-cnt ul {
            width: 240px;
            float: right;
        }

    .social-txt {
        display: table;
        width: 100%;
        padding-right: 10px;
    }

    .coach-form {
        margin-bottom: -178px;
    }

   

    .coach-form-bottom {
        padding: 8px 45px 100px;
    }

    .common-header {
        padding: 70px 0;
    }

    .hero-cnt ul {
        padding-left: 40px;
    }

    .tazman-collapse .collapse-heading {
        padding-top: 50px;
        padding-bottom: 30px;
        text-align: center;
    }

    .contactbg {
        padding: 70px;
        box-shadow: none;
        background-image: url('../images/contactbg.webp');
        background-repeat: no-repeat;
    }

    .section-lg {
        padding: 60px 0;
    }

    .section-md {
        padding: 40px 0;
    }

    .profile-main {
        text-align: left;
        margin-top: 30px;
    }

    .steps-left {
        text-align: left;
        padding-bottom: 10px;
    }

    .step .step-item a {
        padding: 20px 10px 0;
        text-transform: uppercase;
        font-size: 16px;
    }

    .profile-steps {
        margin: 10px 8px 5px 0;
    }

    .abt-main-cnt {
        padding: 60px 0;
    }

    .abtinfo {
        padding: 60px 15px;
    }

    .abt-detail {
        margin-top: 50px;
    }

        .abt-detail .abt-detail-inner {
            padding: 30px;
        }

    .abt-mid-cnt {
        text-align: left;
    }

        .abt-mid-cnt .testi-controls {
            margin-top: -29px;
            padding-right: 0px;
        }

    .pricebox-right {
        padding-right: 0px;
    }

    .pricebox-left {
        padding-left: 0px;
    }

    .pricebox-center {
        padding: 0px;
        margin-top: -40px;
    }

    .price-colorbox {
        padding: 45px 20px;
    }

    .pricing-cnt .tab-content {
        padding: 65px 35px !important;
        margin-bottom: 60px;
    }

    .pricing-cnt .nav-tabs .nav-item, .club-tabs .nav-tabs .nav-item {
        width: auto;
    }

    .btn-facility {
        float: right;
    }

    .btn-booking {
        width: 200px;
    }

    .btn-fav {
        margin-bottom: 20px;
    }



    .gear-box {
        height: 280px;
        margin-bottom: 15px;
    }
    .gear-box .gear-box-active {
        border: 1px solid red;
    }

        .gear-box .footer .price {
            font-size: 24px;
        }

            .gear-box .footer .price span {
                font-size: 14px;
            }

    .overlay .price {
        font-size: 22px;
    }

        .overlay .price span {
            font-size: 14px;
        }

    .sports-lg-icon-main {
        margin-top: 25px;
    }

    .sports-lg-icon {
        margin: 6px;
    }

    .product-details {
        padding: 0px;
    }

    .product-tabs .tab-content {
        background-color: #fbfbfb;
        padding: 20px 30px;
        display: block;
    }

    .venue-top-search .dropdownborder {
        border: none;
    }

    .venue-top-search .venuebtn {
        width: auto;
        margin-left: 10px;
    }

    .venue-top-search .venue-field {
        width: 256px;
    }

    .notification-cnt ul li .img {
        width: 8%;
        height: 45px;
    }

    .notification-cnt {
        padding: 30px;

    }

        .notification-cnt ul li .title {
            font-size: 14px;
        }

    .profile-bg tr {
        font-size: 14px;
    }

    .profile-bg {
        margin-top: -25px;
    }
     .profile-detail-cnt .step .step-item a {
        padding:0px;
        padding-top:18px;
          font-size: 14px;
    }
    .popup-skill .profile-detail-cnt .step .step-item a {
        font-size: 10px;
    }
}


.react-confirm-alert {
    padding: 31px;
    border-radius: 2%;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}
.btn-circle{
    height: 36px;
    width: 36px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 50%;
}
.cursor-normal{
    cursor: default !important;
}

@media screen and (min-width: 1400px) {
    .inner-header-top {
      text-align: unset;
    }
  }


/* 
  Classes for buttons */


  .btn-main {
    color: #fff;
    background-color:#ec3f2b;
    border:1px solid #fc4a1a;
    padding: 0.375rem 0.75rem;
    border-radius:4px;
  }
  
  .btn-main:hover {
    color: #fff;
   background-color:#f9513e;
    border:1px solid #fc4a1a;
  }

.btn-main.disabled, .btn-main:disabled {
    color: #fff;
    background-color: #d23422;
    border-color: #fc4a1a;
 }

 .btn-sub {
    color: #fff;
    background-color:#121010;
    border:1px solid #484747;
    padding: 0.375rem 0.75rem;
    border-radius:4px;
  }
  
  .btn-sub:hover {
    color: #fff;
   background-color:#2f2f2f;
    border:1px solid #2f2f2f;
  }

.btn-sub.disabled, .btn-sub:disabled {
    color: #fff;
    background-color:#2f2f2f;
    border:1px solid #2f2f2f;
 }

 .btn-main-outline {
    color: #ec3f2b;
    background:none;
    border:1px solid #ec3f2b;
    padding: 0.375rem 0.75rem;
    border-radius:4px;
  }
  
  .btn-main-outline:hover {
   border:1px solid #fc4a1a;
  }

  .btn-main-outline .disabled,  .btn-main-outline :disabled {
    border:1px solid #fc4a1a;
 }
 .btn-sub-outline {
    color: #121010;
    background:none;
    border:1px solid #121010;
    padding: 0.375rem 0.75rem;
    border-radius:4px;
  }
  
  .btn-sub-outline:hover {
   border:1px solid #2f2f2f;
   color:#525151;
  }

  .btn-sub-outline .disabled,  .btn-sub-outline :disabled {
    border:1px solid #2f2f2f;
 }



