.booking-table td{
    width:10%;
}

.popup-div {
    width: 100%;
    /* border: 1px solid #ddd; */
    /* max-width: 1100px; */
    margin: 0 auto;
}

.modal-xl{
    max-width: 100% !important;
    margin:24px;
  }
.modal-map{
    max-width: 100% !important;
    height: calc(100vh - 100px);
}
.pop-list {
    border: 1px solid #ddd;
    overflow-y: scroll;
    padding: 10px;
    font-size: 15px;
    height: calc(100vh - 185px);
}

.modal .pop-item-main {
    padding-right: 0px !important;
}

    .pop-list h2, h3 {
        font-size: 16px !important;
        letter-spacing: normal;
        font-weight: 600;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
    }

    .pop-list h3 {
        color: #000;
        font-size: 14px !important;
        border-bottom: none;
        text-transform: none;
        padding-bottom: 0px;
    }

    .pop-list .card-body {
        padding: 0px;
    }

.pop-item-main {
    overflow-y: scroll;
    /* height: 650px; */
    padding-right: 16px;
 
}

.pop-item {
    font-size: 15px;
    border: 1px solid #ddd;
    display: table;
    width: 100%;
}

    .pop-item .header {
        float: left;
        background-color: #f5f5f5;
        max-width: 100px;
    }

    .pop-item .body {
        padding: 5px 10px;
        text-align: left;
        color: #333333;
        float: left;
        width: 220px;
    }

        .pop-item .body a:first-child {
            font-size: 15px;
            font-weight: bold;
            color: #ea3128;
            line-height: normal;
            text-transform: uppercase;
        }

        .pop-item .body a span {
            display: block;
            font-size: 14px;
            text-transform: none;
            font-weight: normal;
            color: #000;
        }

        .pop-item .body .location {
            margin-top: 10px;
            margin-bottom: 10px;
            display: inline-block;
            width: 100%;
        }

    .pop-item .address {
        position: relative;
        padding-left: 20px;
        display: inline-block;
        font-size: 13px;
    }

        .pop-item .address:before, .calendar:before {
            font-family: 'Font Awesome\ 5 Free';
            content: "";
            left: 0;
            position: absolute;
            top: 2px;
            color: #333333;
            font-weight: 900;
        }

    .pop-item .calendar {
        position: relative;
        padding-left: 20px;
        display: inline-block;
        margin-top: 10px;
        font-size: 13px;
    }

        .pop-item .calendar:before {
            content: "";
        }

#example-map-2{
    height: calc(100vh - 185px);
}