.navbar-nav{
    margin-bottom:0px !important;
}
.navmain-cnt {
        box-shadow: 0 8px 6px -6px #e2e2e2;
    background-color: #FFF;
    width: 100%;
    padding:10px 0 0 0;
    position:relative;
}

@media (max-width: 991px) {
    .navmain-cnt .navbar-nav li {
        BORDER-BOTTOM: 1PX SOLID #e2e1e1;
}
}

    .navmain-cnt .navbar {
        padding: 0px;
    }
    .nav-top {
        /* border-top: 1PX SOLID #e2e1e1; */
        margin-top:10px;
        padding-left:0px;
        padding-right:0px;
    }

    
    .navmain-cnt .navbar-nav li:last-child {
            BORDER-BOTTOM:none;
    }

        .navmain-cnt .navbar-nav li a {
            background-color: #fff;
            padding: 15px 15px 15px 15px !important;
            font-weight: 600;
            color: #000 !important;
        }

            .navmain-cnt .navbar-nav li a:hover {
                color: #fff !important;
                background-color: #e92e29;
            }

            .navmain-cnt .navbar-nav li a.active {
                color:#e92e29  !important;
                background-color: #FFF;
            }

.btn-nav {
    position: relative;
    padding: 8px 10px;
    width: initial;
    margin-top: 0;
    font-weight: 400;
    font-size: 22px;
    border-radius: 20px;
    display:none;
}

/* .navmain-cnt .dropdown-menu {
        min-width:6rem;
        width: 100% !important;
    } */
.btn-nav, btn-nav:focus, btn-nav:visited {
    color: #fff !important;
    background: #ea3028;
    background: -moz-linear-gradient(left, #ea3028 0%, #f9b043 100%);
    background: -webkit-linear-gradient(left, #ea3028 0%,#f9b043 100%);
    background: linear-gradient(to right, #ea3028 0%,#f9b043 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ea3028', endColorstr='#f9b043',GradientType=1 );
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    border: 0;
    transition: all .3s ease 0s;
}

    .btn-nav:hover {
        box-shadow: inset 0 0 0 2px #f58f0d;
        color: #fff;
    }

    .btn-nav:after {
        font-family: Font Awesome\ 5 Free;
        content: "";
        padding-right: 6px;
        padding-top: 2px;
        float: left;
        opacity: .8;
        transition: all .3s ease 0s;
        font-weight: 900;
    }

    .btn-nav:hover::after {
        opacity: 1;
    }
.login-mb {
    /* float: left; */
    padding: 10px 10px 8px 0px;
    border-right: none;
    margin-right: 10px;
    display: inline-table;
    margin-left: 10px;
    vertical-align: middle;
}
    .login-mb a {
      color:#ea3028;
    }

.cart-icon {
    padding: 10px 20px 8px 0px;
    border-right: 1px solid #E2DFDF;
    height: auto !important;
    float: left;
    margin-right: 8px;
}

    .cart-icon a {
        color: #000;
    }

    .cart-icon .cart-count {
        position: absolute;
        top:8px;
        margin-left: 13px;
        font-size: 10px;
        font-weight: bold;
        padding: 2px 6px;
        text-align: center;
        color: #ffffff;
        border-radius: 30px;
        background-color: #e92e29;
    }

.en-cnt {
    color: #616161;
    padding-top: 12px;
    font-size: 14px;
}

.logo {
    max-width:100%;
    margin-top: -5px;
}
.navmain-cnt .navbar-toggler {
    font-size:18px;
    color:#000;
    padding:6px;
    background-color:transparent;
    border:none;
    border-radius:initial;
}
.navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none;
    border:none;
}

.navmb {
position: absolute;
    top: 0;
    right:12px;
    width: 165px;
}
.navmb .dropdown-menu {
    left: -10px !important;
    top:5px !important;
}



@media (max-width: 1800px) and (min-width: 1200px) {
    .login-mb {
        display:none;
    }
    .logo {
        max-width:100%;
    }
    .navmain-cnt {
    padding:0px;
    }
        .navmain-cnt .navbar-nav li {
        border-bottom:0px;
        }
    .navmain-cnt .navbar-nav li a {
        padding: 15PX !important;
    }
    .navmain-cnt .navbar-nav li a.active {
    color: #fff !important;
    background-color: #e92e29;
}

    .nav-right {
        float: right;
    }

    .nav-pad-right {
        padding-right: 0px;
    }

    .btn-nav {
        margin-top: -5px;
         display:inline-block;
    }

    /* .navmain-cnt .dropdown-menu {
        min-width: 7rem;
        width: 100% !important;
    } */

    .cart-icon {
        position: relative;
        display: inline-block;
        float: none;
        padding: 10px 20px 10px 10px;
        margin-left: 6px;
        margin-right:0px;
    }

    .en-main {
        float: right;
    }


    .en-cnt a {
        color: #616161;
        padding-top: 12px;
        font-size: 14px;
        float: left;
    }


    
      .btn-nav:after {
        font-family: Font Awesome\ 5 Free;
        content: "";
        padding-right: 6px;
        padding-top: 2px;
        float: left;
        opacity: .8;
        transition: all .3s ease 0s;
        font-weight: 900;
    }
      .navmb {
position:initial;
}
      .nav-top {
        border-top:0px;
        margin-top:0px;
        padding-left:0px;
        padding-right:0px;
    }
}


@media (max-width: 1800px) and (min-width: 1200px) {
    .login-mb {
        display:none;
    }
    .logo {
        max-width:100%;
    }
    .navmain-cnt {
    padding:0px;
    }
        .navmain-cnt .navbar-nav li {
        border-bottom:0px;
        }
    .navmain-cnt .navbar-nav li a {
        padding: 15PX !important;
    }
    .navmain-cnt .navbar-nav li a.active {
    color: #fff !important;
    background-color: #e92e29;
}

    .nav-right {
        float: right;
    }

    .nav-pad-right {
        padding-right: 0px;
    }

    .btn-nav {
        margin-top: -5px;
         display:inline-block;
    }

    /* .navmain-cnt .dropdown-menu {
        min-width: 7rem;
        width: 100% !important;
    } */

    .cart-icon {
        position: relative;
        display: inline-block;
        float: none;
        padding: 10px 20px 10px 10px;
        margin-left: 6px;
        margin-right:0px;
    }

    .en-main {
        float: right;
    }


    .en-cnt a {
        color: #616161;
        padding-top: 12px;
        font-size: 14px;
        float: left;
    }


    
      .btn-nav:after {
        font-family: Font Awesome\ 5 Free;
        content: "";
        padding-right: 6px;
        padding-top: 2px;
        float: left;
        opacity: .8;
        transition: all .3s ease 0s;
        font-weight: 900;
    }
      .navmb {
position:initial;
}
      .nav-top {
        border-top:0px;
        margin-top:0px;
        padding-left:0px;
        padding-right:0px;
    }
}
@media (max-width: 991px) {
    .login-mb {
        border-right: 1px solid #E2DFDF;
    }
}