/*
 * Bootstrap
 */
/*@import "~bootstrap/dist/css/bootstrap-reboot.css";*/
/*@import "~bootstrap/dist/css/bootstrap-grid.css";*/
/*@import "~bootstrap/dist/css/bootstrap.css";*/


/*
 * Font awesome
 */
@import "~@fortawesome/fontawesome-free/css/all.css";

@import '~owl.carousel/dist/assets/owl.carousel.css';
@import '~owl.carousel/dist/assets/owl.theme.default.css';
/*
 * Modules
 */
@import "~tazman-design/css/form.css";
@import "~tazman-design/css/main.css";
@import "~tazman-design/css/utils.css";
@import "~tazman-design/css/nav.css";
@import "~tazman-design/css/user-nav.css";
/*@import "~tazman-design/css/responsive-calendar.css";*/
/*@import "~tazman-design/css/admin.css";*/
@import "~tazman-design/css/booking-popup.css";
/*@import "~tazman-design/css/calendar.css";*/
/*@import "~tazman-design/css/chatbox.css";*/
@import "~tazman-design/css/club.css";
/* @import "~tazman-design/css/club-details.css"; */
@import "~tazman-design/css/coach.css";
/*@import "~tazman-design/css/dashboard.css";*/
/*@import "~tazman-design/css/gears.css";*/
/*@import "~tazman-design/css/home.css";*/
@import "~tazman-design/css/players.css";
@import "~tazman-design/css/stats.css";
/*@import "~tazman-design/css/steps.css";*/
/*@import "~tazman-design/css/wallet.css";*/
/*@import "~tazman-design/css/input.css";*/
/* @import "~tazman-design/css/site.css"; */
@import "~tazman-design/css/site.css";
@import "~tazman-design/css/homepage.css";
@import "~tazman-design/css/coach-detail.css";
@import "~tazman-design/css/venue-detail.css";




.social-cnt i {
  font-size: 16px;
  background: #6b6767;
  width: 33px;
  height: 33px;
  line-height: 16px;
  border-radius: unset;
  color: #fff;
  padding: 8px;
  transition: all .4s ease;
}
.social-cnt ul {
  width: 205px;
  float: right;
}

.social-cnt ul li{
  margin : 0px 8px;
}

.avtarOuterDiv {
  height : 100% !important;
}

/* .avtarimg {
    height : 71% !important;
} */


.direction-ltr {
  direction: ltr;
}

.direction-rtl {
  direction: rtl;
}
.lang-selector{
  width: 60px
}
.abdul-__menu{
  color: black;
}