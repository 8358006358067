body {
    font-family: 'Poppins', sans-serif;
  color:#000;
 }
 .coach-detail-header {
     background-color:#f8f8f8; 
     width: 100%;
     padding: 30px 0;
 }
 .coach-detail-header .coach-name {
 font-size:22px;
 color: #000;
 font-weight: bold;
 padding-top: 10px;
 }
 .coach-detail-header .coach-name span {
     color: #27282d;
     font-size:14px;
     display: block;
     font-weight: normal;
 }
 
 .coach-detail-header .address {
     position: relative;
     padding-left:20px;
     display: inline-block;
     width: 100%;
     font-size:13px;
     color: #57585e;
     font-weight: normal;
     padding-bottom: 10px;
 }
 .coach-detail-header .address:before {
     font-family: "Font Awesome 5 Free";
     content: "";
     left: 0px;
     position: absolute;
     top:0px;
     color:#a9a4a4;
     font-weight: 900;
     font-size:14px;
 }
 .coach-detail-md {
     padding:30px 0;
 }
 .coach-detail-md h2 {
     border-left: 4px solid #e92e29;
     font-size:20px !important;
     font-weight:600;
     padding-left: 10px;
     margin-bottom: 20px;
 }
 .coach-detail-md p {
 font-size: 14px;
 }
 .coach-float {
 background-color:#fff;
 border:2px solid #f1f1f1;
 padding:15px;
 }
 .coach-float h3 {
     font-size:16px;
     font-weight: bold;
     color:#262626;
 }
 
 .coach-float .address, .email, .phone, .cal {
     position: relative;
     padding-left:26px;
     display: inline-block;
     width: 100%;
     font-size:13px;
     font-weight: normal;
     padding-bottom: 10px;
 }
 .coach-float .address:before, .email:before, .phone:before, .cal:before {
     font-family: "Font Awesome 5 Free";
     content: "";
     left: 0px;
     position: absolute;
     top: 2px;
     color:#e92e29;
     font-weight: 900;
     font-size:14px;
 }
 .coach-float .email:before {
     content: "";
 }
 .coach-float .phone:before {
     content: "";
 }
 .coach-float .cal:before {
     content: "";
 }
 .coach-terms {
     background-color: #fef9e3;
     margin-top: 20px;
     font-size: 12px;
     padding:15px;
     margin-top: 25px;
     border: 1px dashed #908d8d;
 }
 .coach-terms h3 {
 font-size: 16px;
 color: #000;
 font-weight: 600;
 }
 .coach-terms p {
 font-size:12px;
 margin-bottom: 0px;
 }
 
 .coach-float-floating {
     z-index: 999;
     position: fixed;
     top: 0;
     width:255px;
 }
 
 .btn-main {
     color: #fff;
     background-color:#ec3f2b;
     border:1px solid #fc4a1a;
     padding: 0.375rem 0.75rem;
     border-radius:4px;
   }
   
   .btn-main:hover {
     color: #fff;
    background-color:#f9513e;
     border:1px solid #fc4a1a;
   }
 
 .btn-main.disabled, .btn-main:disabled {
     color: #fff;
     background-color: #d23422;
     border-color: #fc4a1a;
  }
 
 
 
  @media (max-width:767px) {
     .venue-top h1 {
         font-size:18px;
     }
     .venue-top h1 span {
         font-size:12px;
     }
     .venue-result {
     padding-bottom: 10px;
     }
   .v-h-align {
         text-align:left;
         padding-top: 10px;
     }
     .siderbar-float-floating {
        
         position:relative;
         width:100%;
      }
 }
 