.inbox-scroll{
    overflow-y: scroll;
    height: 100%;
    scrollbar-width: thin;
} 
.scroll-width{
    scrollbar-width: thin;
}
.pad-right {
    padding-right: 0px;
}

.ptop-20 {
    padding-top: 20px;
}

.ptop-30 {
    padding-top: 30px;
}

.pad-0 {
    padding: 0px;
}
.p55 {
    padding:55px !important;
}
.p15 {
    padding:15px;
}
.pt150 {
    padding-top:150px !important;
}
.mb-padding {
    padding-left:15px;
    padding-right:15px;
}

/*font-face color css*/
.font-orange {
    color:#e83426 !important;
}
.font-grey {
    color:#999999 !important;
} 
.font-black {
    color:#000 !important;
}
.fnormal {
    text-transform:none;
    letter-spacing:normal;
}
.font-exo {
        font-family: 'Exo 2', sans-serif;
        
}

/*btn*/
.btn-outline-plain {
    color: #7e7e80;
    background-color: transparent;
    background-color:#e9ecf3;
    font-size:14px !important;
}
.btn-full {
width:100%;
margin-bottom:10px;
}
.btn-outline-white {
    color: #000;
    background-color: transparent;
    background-image: none;
        border-color: #dcdcdc;
    background-color: #ffffff;
    font-size:12px !important;
}

/*font sizes*/
.f20, a.f20 {
    font-size:20px !important;
}
.f10, a.f10 {
    font-size:10px;
}
.f12, a.f12 {
    font-size:12px;
}   
.f13, a.f13 {
    font-size:13px;
}
.f14, a.f14 {
    font-size:14px !important;
} 
.f16, a.f16 {
    font-size:16px !important;
}
.f18, a.f18 {
    font-size:18px !important;
} 
.f26, a.f26 {
    font-size:26px !important;
}

/*margin css*/
.ml-2 {
    margin-left:2px;
}

/*background color css*/
.grey-bg {
    background-color:#f5f5f5;
}
.white-bg {
    background-color:#fff !important;
}


ul li {
    margin:0px;
    padding:0px;
}
.mb-center {
    text-align:center;
}
@media (max-width:767px) { 
    .badge-offline, .badge-online, .badge-away {
        /* position: absolute; */
        top: 36px;
        right: 190px;
    }
}
@media (max-width: 1800px) and (min-width: 1200px) {
.mb-center {
    text-align:left;
}
.btn-full {
width:auto;
margin-bottom:10px;
}
.mb-padding {
    padding-left:0px;
    padding-right:0px;
}
    .btn-outline-white {
    font-size:14px !important;
    }
}
.badge-away, .badge-online, .badge-offline{
    display: inline-block !important;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    vertical-align: middle;
}
.badge-online{
    background:  rgb(60, 192, 91);
    box-shadow: 0 0 0 3px rgba(118, 118, 118, 0.3);
}
.badge-away{
    background: rgb(252, 247, 0);
    box-shadow: 0 0 0 1px rgba(252, 247, 0, 0.3);
}
.badge-offline{
    background: rgb(118, 118, 118);
    box-shadow: 0 0 0 3px rgba(118, 118, 118, 0.3);
}
.sticky-header{
    position: sticky;
    top: 0;
    z-index: 1;
}
.table-xs td, .table-xs th{
    padding: 0.2rem;
}