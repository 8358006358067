.ant-select-dropdown-menu{
    max-height:120px;
}


.coach-header {
    width: 100%;
    padding: 20px;
    background-image: url('../images/coach-bg.webp');
    background-repeat: repeat-x;
    background-position: center top;
}

.form-left {
    padding-bottom: 20px;
    padding-top: 20px;
}

    .form-left p {
        color: #fff;
        font-size: 14px;
        text-align: center;
    }



.ch-shadow {
    -webkit-box-shadow: -23px -20px 0px 4px rgba(242,242,242,1);
    -moz-box-shadow: -23px -20px 0px 4px rgba(242,242,242,1);
    box-shadow: -23px -20px 0px 4px rgba(242,242,242,1);
    width: 60%;
    margin: 35px auto 50px;
}

.ch-txt {
    width: 100%;
    /* padding-top: 100px; */
    padding-bottom: 75px;
}

.ch-box-cnt {
    background-image: url(../images/chbg02.webp);
    background-position: top right;
    background-repeat: no-repeat;
    padding: 20px 30px 20px 20px;
    width: 100%;
    margin-top: -50px;
    text-align: center;
}

    .ch-box-cnt .ch-heading {
        font-size: 24px;
        font-weight: bold;
        color: #000;
        text-transform: none;
    }

.ch-main-cnt {
    width: 100%;
    padding: 0;
    text-align: center;
}

.ch-box-inner {
    width: 100%;
    display: table;
    padding: 20px 0;
}

    .ch-box-inner p {
        color: #908f8f;
        font-size: 15px;
    }

.ch-box-icon {
    padding-right: 8px;
    width: 100%;
    padding: 10px;
}

.ch-box-txt {
    font-weight: normal;
    font-size: 16px;
}

.ch-info-main {
    background-image: url(../images/ch-info-bg.webp);
    background-repeat: repeat-x;
    background-position: center top;
    padding: 30px 0 20px;
    width: 100%;
    color: #fff;
}

    .ch-info-main h3 {
        color: #fff !important;
        font-size: 20px !important;
    }

.ch-info-box {
    width: 100%;
    padding: 30px 0 20px 0;
    color: #fff;
    display: inline-block;
}

    .ch-info-box .ch-icon {
        float: left;
        padding-top: 6px;
    }

    .ch-info-box img {
        width: 70%;
    }

    .ch-info-box .ch-info-txt {
        font-weight: bold;
        font-size: 30px;
    }

    .ch-info-box .ch-info-detail {
        font-size: 12px;
        width: 100%;
        padding-top: 5px;
    }

        .ch-info-box .ch-info-detail span {
            display: block;
            font-weight: bold;
            font-size: 16px;
            padding-bottom: 6px;
        }

.ch-abt-cnt {
    max-width: 1000px;
    position: relative;
    padding: 40px 15px;
}

    .ch-abt-cnt .ch-img {
        display: none;
    }

    .ch-abt-cnt .ch-abt-inner {
        max-width: 490px;
    }

.ch-review-cnt {
    width: 100%;
    padding: 30px 0 60px 0;
    text-align: center;
}

.ch-join-cnt {
    background-image: url(../images/join-bg.webp);
    background-position: center top;
    background-repeat: no-repeat;
    text-align: center;
    padding: 70px 0;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
}
/*coach profile css*/
.pill-outline {
    border: 1px solid #f7a641;
    padding: 4px 10px;
}

.coach-details {
    border: 1px solid #dee2e6;
    text-align: center;
    font-size: 14px;
    padding: 6px 0;
    color: #000;
}

    .coach-details.fst {
        color: #999 !important;
    }

.ch-main {
    padding: 10px 14px;
}

.exp-cnt .date {
    position: relative;
    display: block;
}

    .exp-cnt .date:before {
        font-family: 'Font Awesome\ 5 Free';
        content: "";
        position: absolute;
        color: #e92e29;
        font-weight: 700;
    }

    .exp-cnt .date span {
        font-weight: 400;
        color: #e92e29;
        line-height: normal;
        font-size: 16px;
        padding-left: 20px;
    }

.exp-cnt .exp-inner {
    padding: 10px 0;
}

.coach-rating {
    color: #e92e27;
    font-size: 22px;
    font-weight: bold;
    line-height: normal;
}

    .coach-rating span {
        color: #b1b5b8;
    }

.coach-review-cnt {
    padding-top: 20px;
    width: 100%;
}

.coach-review-main {
    border: 1px solid #ea3329;
    padding: 20px;
    width: 100%;
    border-radius: 6px;
}

.coach-grey-cnt {
    border: 1px solid #cacaca;
    padding: 20px;
    width: 100%;
    border-radius: 6px;
    margin-top: 20px;
}

.scprofile-cnt {
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding-bottom: 10px;
}

.scprofile-img {
    float: left;
    padding-right: 10px;
    width: 32%;
    margin-right: 6px;
}

.reply-cnt {
    padding: 0 8px;
}

.reply-img, .message-img {
    float: left;
    padding-right: 10px;
    width: 32%;
    margin-right: 6px;
}
.message-img {
      width:24%;
}

.scprofile-title {
    font-size: 16px;
    color: #000;
    display: table;
    margin-top: 5px;
    line-height: normal;
}

    .scprofile-title span {
        font-size: 12px;
        color: #999;
        display: block;
    }

    .scprofile-title p {
        color: #999999;
        font-size: 14px;
    }

.comments, .reply, .reply-btn, .smcomments, .smreply {
    position: relative;
    padding-left: 28px;
    display: inline-block;
    color: #000;
    font-size: 20px;
    margin-top: 6px;
    text-transform: uppercase;
    font-weight: 600;
    float: right;
}
.smcomments {
   float: left;
   font-size:14px;
   color:#999;
   padding-left:20px;
   text-transform:none;
}
.smreply {
float: left;
   font-size:14px;
   color:#999;
   padding-left:20px;
   text-transform:none;
   margin-left:15px;
}

.reply-btn {
    float: left;
}

.reply-btn-margin {
    margin-top: -42px;
    float: right;
    margin-right: 10px;
}

.reply {
    float: none;
    text-transform: none;
    padding-bottom: 20PX;
}

    .comments:before, .reply:before, .reply-btn:before, .smcomments:before, .smreply:before {
        font-family: 'Font Awesome\ 5 Free';
        content: "";
        left: 0;
        position: absolute;
        top: 2px;
        color: #e92e27;
        font-weight: normal;
    }
.smcomments:before {
     content: "";
    color:#999;
}
.smreply:before {
    content: "";
    color:#999;
    font-weight:bold;
}

    .reply:before {
        content: "";
        font-weight: bold;
    }

.reply-btn:before {
    content: "";
    font-weight: bold;
    font-size: 30px;
}

.reply-text-box {
    margin-top: 10px;
    height: 90px !important;
}

.navbar-toggler {
    z-index: 2
}

.coach-notifications .inner-header-top,
.coach-messages .inner-header-top {
    padding: 30px 0 80px;
}

.coach-price .save-button-wrapper {
    clear: both
} 
    
.coach-guide img {
    width: 100%;
    font-size: 124px;
}

.coach-guide p {
    font-size: 20px;
    margin: 10px 0 10px 0;
}


@media (max-width: 1800px) and (min-width: 1200px) {
    .ch-shadow {
        width: auto;
        margin: 75px 75px 0;
    }

    .ch-txt {
        padding-top: 200px;
    }

    .ch-box-inner {
        padding: 40px 0;
    }

    .ch-info-main {
        padding: 60px 0;
    }

    .ch-box-cnt {
        padding: 50px 10px 50px 10px;
    }

        .ch-box-cnt .ch-heading {
            padding-top: 60px;
        }

    .ch-main-cnt {
        padding: 10px 20px 0 20px;
    }

    .ch-box-icon {
        float: left;
        max-width: 80px;
    }

    .ch-box-txt {
        padding: 0 0 0 12px;
        float: left;
        max-width: 230px;
        text-align: left;
    }

    .ch-info-box img {
        width: 100%;
    }

  


    .ch-info-box .ch-info-txt {
        font-size: 40px;
        padding-left: 10px;
        display: inline;
    }

    .ch-info-box {
        padding: 40px 0 110px 0;
    }

        .ch-info-box .ch-info-detail {
            font-size: 14px;
        }

    .ch-abt-cnt {
        padding: 60px;
        background-color: #fff;
        position: relative;
        top: -80px;
    }

        .ch-abt-cnt .ch-img {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
        }

    .ch-review-cnt {
        padding: 0 0 70px 0;
    }

    .ch-join-cnt {
        padding: 100px 0 110px;
    }

    .coach-form {
        margin-bottom: -178px;
    }

   

    .coach-form-bottom {
        padding: 10px 45px 100px;
    }

    .form-left {
        padding-top: 220px;
        padding-right: 120px;
    }

        .form-left p {
            text-align: left;
            padding-left: 12px;
        }

    .exp-cnt .exp-inner {
        padding: 10px;
    }

    .scprofile-img {
        width: 10%;
    }

    .scprofile-title {
        font-size: 18px;
    }

        .scprofile-title span {
            font-size: 14px;
        }

    .coach-rating {
        font-size: 30px;
    }

    .reply-img {
        width: 7%;
    }

    .reply-cnt {
        padding: 0 30px;
    }

    .reply-text-box {
        margin-top: 10px;
        height: 90px !important;
    }

    .reply-btn-margin {
        margin-top: 10px;
        float: none;
    }

    .message-img {
        width: 14%;
    }
}
@media (min-width: 768px){
    .desk-time{
        margin-right:-100px
    }
}
@media (max-width: 768px) and (min-width: 600px) {
    .coach-form {
        width: 100% !important;
        background-color: #fff;
        box-shadow: 1px 1px 13px 0 #d6cfd6;
    }
    .coach-form .checkbox {
        padding-left: 20px;
    }
    .coach-form-bottom {
        background-image: none;
        padding: 10px 20px 50px;
    }
    .coach-form-top {
        background-image: none; 
    }
    .club-box {
        border:none;
    }
}
.navigation-buttons{
    float: right;
}
.color-white{
    color: white;
}